import React from 'react';
import '../../../res/styles/cloud-asset-form.scss';
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {CloudAssetsModule,} from "@modules/CloudAssetsModule";
import {ImagePopUp} from "./ImagePopUp";
import {
    BaseComponent,
    DialogModule,
    GenericSelect,
    ToastModule,
    TS_Checkbox
} from "@intuitionrobotics/thunderstorm/frontend";
import {Loader} from "../../../widgets/Loader";
import {buttonStyle} from "@styles/buttonStyle";
import {ConfirmDialog} from "../ui/dialogs/ConfirmDialog";
import {CustomField} from "../ui/custom-fields/CustomField";
import {ObjectTS, StringMap} from "@intuitionrobotics/ts-common";
import {TypedMap} from "@intuitionrobotics/ts-common/utils/types";
import {ChipGroup} from "../ui/chips/ChipGroup";
import {Chip} from "../ui/chips/Chip";
import {ICONS} from "@res/icons";
import {COLORS} from "@res/colors";
import DropdownWithCheckboxes, {CheckboxOption, selectAllCheckboxOption} from '../ui/DropdownWithCheckboxes';
import {
    SQL_CloudAssetCaption,
    SQL_CloudAssetLandmark,
    SQL_CloudAssetTag,
    SQL_CloudAssetWithUrl
} from "@app/ir-q-app-common/types/cloud-asset";
import {TypesOfCloudAsset} from "@app/app-shared/image-config";
import {DataEnvsModule} from "@modules/DataEnvsModule";

const KILOBYTES = 1024;
const MEGABYTES = 1024 * KILOBYTES;
const GIGABYTES = 1024 * MEGABYTES;
export const INTENT_PARAMETERS_NAME = "intentParameters";
export const TAGS_NAME = "tags";
export const LANDMARKS_NAME = "landmarks";
export const CAPTIONS_NAME = "captions";
const VERSION_REGEX = /^\d{1,2}\.\d{1,2}\.\d{1,2}$/

type CloudAssetFormProps = {
    asset: SQL_CloudAssetWithUrl;
    selectedKey: string;
    imageUrl?: string;
};

type EditingMap = {
    fileName?: string;
    description?: string,
    photographer?: string,
    landmark?: string,
    location?: string,
    subject?: string,
    categories?: string,
    intent?: string;
    source_data?: string;
    typeOfImage?: keyof TypesOfCloudAsset;
    isSuggestion?: boolean,
    isHidden?: boolean,
    from_version?: string;
    to_version?: string;
    intent_params?: TypedMap<string>;
    tags?: SQL_CloudAssetTag[];
    landmarks?: SQL_CloudAssetLandmark[];
    captions?: SQL_CloudAssetCaption[];
    user_groups?: string[]
};

type CloudAssetFormState = {
    isEditing: boolean;
    showLoader: boolean;
    errorOnFromVersionValidate?: boolean;
    errorOnToVersionValidate?: boolean;
    editingMap: EditingMap;
    isUserGroupsDropdownOpen?: boolean
};


class CloudAssetForm extends BaseComponent<CloudAssetFormProps, CloudAssetFormState> {
    constructor(props: CloudAssetFormProps) {
        super(props);

        this.state = {
            isEditing: false,
            showLoader: false,
            editingMap: {
            },
        };
    }

    cancelChanges = () => {
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isEditing = false;
            currState.editingMap = {};
            currState.isUserGroupsDropdownOpen = false;
            return currState;
        })
    }

    validateFromVersion = (event: any): void => {
        if (this.state.editingMap.from_version && !VERSION_REGEX.test(this.state.editingMap.from_version)) {
            ToastModule.toastError("Version format is x.x.x");
            return this.setState({errorOnFromVersionValidate: true});
        }

        this.setState({errorOnFromVersionValidate: false});
    }
    validateToVersion = (event: any): void => {
        if (this.state.editingMap.to_version && !VERSION_REGEX.test(this.state.editingMap.to_version)) {
            ToastModule.toastError("Version format is x.x.x");
            return this.setState({errorOnToVersionValidate: true});
        }

        this.setState({errorOnToVersionValidate: false});
    }

    handleChange = (event: any): void => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isEditing = true;
            currState.editingMap[name as keyof EditingMap] = value as never;
            return currState;
        })
    }

    handleIntentParameterChanged = (value: StringMap): void => {
        this.setState((prevState) => {
            const currState = {...prevState};
            // when a user edits a field we show the save/cancel buttons
            currState.isEditing = true;
            currState.editingMap.intent_params = value as never;
            return currState;
        })
    }

    addNewAnnotation = (newEntry: string): void => {
        const newAnnotation: SQL_CloudAssetTag = {
            id: 0,
            asset_id: this.props.asset.id,
            original_description: newEntry,
            description: newEntry.replace(/ /g, "_"),
            score: 1,
            confidence: 1,
            topicality: 1
        };
        const asset = this.props.asset;
        if (!asset)
            return

        this.setState((prevState) => {
            const currState = {...prevState};
            // when a user edits a field we show the save/cancel buttons
            currState.isEditing = true;
            currState.editingMap.tags = (currState.editingMap.tags || asset.tags?.slice(0) || []) as SQL_CloudAssetTag[];
            currState.editingMap.tags.push(newAnnotation);

            return currState;
        })
    }

    handleHidden = (key: string, prevChecked: boolean) => {
        this.setState((prevState) => {
            const currState = {...prevState};

            currState.isEditing = true;
            currState.editingMap.isHidden = !prevChecked;

            return currState;
        })
    };

    handleTypeOfImage = (type: TypesOfCloudAsset) => {
        this.setState((prevState) => {
            const entry = Object.entries(TypesOfCloudAsset).find(([, v]) => v === type);
            if (!entry) {
                ToastModule.toastError("Invalid type of image, " + type);
                return prevState;
            }

            const currState = {...prevState};

            currState.isEditing = true;
            currState.editingMap.typeOfImage = entry[0] as keyof TypesOfCloudAsset;
            currState.editingMap.isSuggestion = entry[0].toString().toLowerCase().includes("suggestion");

            if (!currState.editingMap.isSuggestion)
                currState.editingMap.categories = "";
            else
                currState.editingMap.categories = "suggestion"

            return currState;
        })
    };

    handleUserGroups = (selectedUserGroups: string[]) => {
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.editingMap.user_groups = selectedUserGroups;
            currState.isEditing = true;
            return currState;
        })
    };

    createUserGroupOptions(): CheckboxOption[] {
        return DataEnvsModule.getItems().map((envItem) => {
            return {value: envItem.label, label: envItem.label}
        });
    }

    getSelectedUserGroups(asset: SQL_CloudAssetWithUrl): string[] {
        if (this.state.isEditing) {
            return this.state.editingMap.user_groups || [selectAllCheckboxOption.value];
        }
        return asset.user_groups && asset.user_groups.length > 0 ? asset.user_groups.split(",") : [selectAllCheckboxOption.value];
    }

    getPlaceholderForUserGroupsDropdown(asset: SQL_CloudAssetWithUrl): string {
        if (this.getSelectedUserGroups(asset).includes(selectAllCheckboxOption.value)) {
            return "All user groups";
        }
        return "Selected groups";
    }

    toggleUserGroupsDropdown = (event: any) => {
        event.preventDefault();
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isUserGroupsDropdownOpen = !prevState.isUserGroupsDropdownOpen;
            return currState;
        })
    };

    closeUserGroupsDropdown = (event: any) => {
        event.preventDefault();
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isUserGroupsDropdownOpen = false;
            return currState;
        })
    };


    getTypeOfAsset(asset: SQL_CloudAssetWithUrl): TypesOfCloudAsset {
        let typeOfImage: keyof TypesOfCloudAsset | undefined;
        if (!this.state.isEditing) {
            typeOfImage = asset.metadata.typeOfImage;
        } else {
            typeOfImage = this.state.editingMap.typeOfImage || asset.metadata.typeOfImage;
        }
        if (typeOfImage) {
            const entry = Object.entries(TypesOfCloudAsset).find(([k]) => k === typeOfImage);
            if (entry)
                return entry[1]
        }
        if (asset.metadata.isSuggestion)
            return TypesOfCloudAsset.suggestion_top;

        return TypesOfCloudAsset.landscape;
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        const asset = this.props.asset;
        if (this.state.editingMap.isHidden !== undefined)
            asset.active = !this.state.editingMap.isHidden;

        let user_groups = this.state.editingMap.user_groups || (asset.user_groups ? [asset.user_groups] : []);
        if (user_groups?.length) {
            if (user_groups.includes(selectAllCheckboxOption.value))
                asset.user_groups = undefined;
            else
                asset.user_groups = user_groups.join(",");
        }

        if (!!this.state.editingMap.description)
            asset.description = this.state.editingMap.description


        if (!!this.state.editingMap.fileName)
            asset.metadata.fileName = this.state.editingMap.fileName;

        if (!!this.state.editingMap.photographer)
            asset.metadata.photographer = this.state.editingMap.photographer;

        if (!!this.state.editingMap.landmark)
            asset.metadata.landmark = this.state.editingMap.landmark;

        if (!!this.state.editingMap.location)
            asset.metadata.location = this.state.editingMap.location;

        if (!!this.state.editingMap.subject)
            asset.metadata.subject = this.state.editingMap.subject;

        if (!!this.state.editingMap.categories)
            asset.metadata.categories = this.state.editingMap.categories;

        if (!!this.state.editingMap.typeOfImage)
            asset.metadata.typeOfImage = this.state.editingMap.typeOfImage;

        asset.metadata.isSuggestion = this.state.editingMap.isSuggestion;

        if (asset.metadata.categories?.length === null)
            asset.metadata.categories = undefined;

        asset.tags = this.state.editingMap.tags || asset.tags || [];
        asset.landmarks = this.state.editingMap.landmarks || asset.landmarks || [];
        asset.captions = this.state.editingMap.captions || asset.captions || [];

        const intent = this.state.editingMap.intent || asset.intent;
        if (intent && intent.length > 0)
            asset.intent = intent;
        else
            asset.intent = undefined;

        const intent_params = this.state.editingMap.intent_params || asset.intent_params;
        if (intent_params && Object.keys(intent_params).length > 0)
            asset.intent_params = intent_params;
        else
            asset.intent_params = undefined;

        const source_data = this.state.editingMap.source_data || asset.source_data;
        if (source_data && source_data.length > 0)
            asset.source_data = source_data;
        else
            asset.source_data = undefined;

        const from_version = this.state.editingMap.from_version || asset.from_version;
        if (from_version && from_version.length > 0)
            asset.from_version = from_version;
        else
            asset.from_version = undefined;

        const to_version = this.state.editingMap.to_version || asset.to_version;
        if (to_version && to_version.length > 0)
            asset.to_version = to_version;
        else
            asset.to_version = undefined;

        this.setState({showLoader: true})
        CloudAssetsModule.update(asset).finally(() => this.setState({showLoader: false}));
        this.cancelChanges();
    }
    private prettyPrintFileSize = (fileSize: string) => {
        const size = parseInt(fileSize);
        if (isNaN(size)) {
            return '';
        }
        if (size < KILOBYTES) {
            return size + ' B';
        }
        if (size < MEGABYTES) {
            return (size / KILOBYTES).toFixed(2) + ' KB';
        }
        if (size < GIGABYTES) {
            return (size / MEGABYTES).toFixed(2) + ' MB';
        }
        return (size / GIGABYTES).toFixed(2) + ' GB';
    }

    render() {
        const asset = this.props.asset;
        if (!asset)
            return <div></div>

        if (this.state.showLoader) {
            return <Loader/>;
        }

        const sortAnnotations = (a: SQL_CloudAssetTag | SQL_CloudAssetLandmark | SQL_CloudAssetCaption, b: SQL_CloudAssetTag | SQL_CloudAssetLandmark | SQL_CloudAssetCaption) => b.score - a.score;
        const tagToScore = (this.state.editingMap.tags || asset.tags || []).sort(sortAnnotations).reduce((toRet: ObjectTS, item: SQL_CloudAssetTag) => {
            toRet[item.original_description || item.description] = item.score;
            return toRet;
        }, {} as ObjectTS) as { [tag: string]: Number };

        const landmarks = (asset.landmarks || []).sort(sortAnnotations).reduce((toRet: ObjectTS, item: SQL_CloudAssetLandmark) => {
            toRet[item.description] = item.score;
            return toRet;
        }, {} as ObjectTS) as { [tag: string]: Number };

        const captions = (asset.captions || []).sort(sortAnnotations).reduce((toRet: ObjectTS, item: SQL_CloudAssetCaption) => {
            toRet[item.description] = item.score;
            return toRet;
        }, {} as ObjectTS) as { [tag: string]: Number };

        const disableSaveBtn = this.state.errorOnFromVersionValidate || this.state.errorOnToVersionValidate;

        const typeOfAssetOptions = Object.values(TypesOfCloudAsset);
        const selectedTypeOfAsset = this.getTypeOfAsset(asset);

        const userGroupOptions = this.createUserGroupOptions();
        const selectedUserGroups = this.getSelectedUserGroups(asset);
        const placeholderForUserGroupsDropdown = this.getPlaceholderForUserGroupsDropdown(asset);

        return <div className="cloud-asset-info">
            <form className="cloud-asset-form" onSubmit={this.handleSubmit}>
                <div className="cloud-asset-image">
                    {
                        asset.signed_url ?
                            <ImagePopUp src={this.props.imageUrl || asset.signed_url}></ImagePopUp> : <div></div>
                    }
                    <div style={{width: 250}} onFocus={this.closeUserGroupsDropdown}>
                        {
                            <GenericSelect<TypesOfCloudAsset>
                                options={typeOfAssetOptions}
                                selectedOption={selectedTypeOfAsset}
                                iconClose={ICONS.triangle_up(COLORS.grays_dark, 12)}
                                iconOpen={ICONS.triangle_down(COLORS.grays_dark, 12)}
                                onChange={(key: TypesOfCloudAsset) => {
                                    this.handleTypeOfImage(key)
                                }}
                                styles={{}}
                                presentation={option => option}
                            />
                        }

                    </div>

                    <div style={{width: 250}}>
                        {
                            <DropdownWithCheckboxes
                                options={userGroupOptions}
                                selectedOptions={selectedUserGroups}
                                onUpdate={(selectedOptions: string[]) => {
                                    this.handleUserGroups(selectedOptions)
                                }}
                                onDropdownToggle={(event: any) => {
                                    this.toggleUserGroupsDropdown(event)
                                }}
                                isOpen={this.state.isUserGroupsDropdownOpen || false}
                                placeholder={placeholderForUserGroupsDropdown}
                                hasSelectAllCheckbox={true}
                            />
                        }

                    </div>

                    <div className="cloud-asset-form-buttons">
                        <PermissionsComponent url={"/v1/cloud-asset/delete"}>
                            <div style={buttonStyle("red")} onClick={() => {
                                ConfirmDialog.show(
                                    {
                                        okLabel: "Delete image",
                                        onOk: () => {
                                            this.setState({showLoader: true});
                                            CloudAssetsModule.delete(asset).finally(() => this.setState({showLoader: false}));
                                            DialogModule.close();
                                        },
                                        title: "Delete image",
                                        displayMessage: `Are you sure you want to delete this image?`
                                    });

                            }}>
                                Delete
                            </div>
                        </PermissionsComponent>
                        <div className="cloud-asset-form-toggle">
                            {
                                <TS_Checkbox
                                    value={''}
                                    checked={this.state.editingMap.isHidden !== undefined ? this.state.editingMap.isHidden : (!asset.active || false)}
                                    label={'Hide image'}
                                    onCheck={this.handleHidden}/>
                            }
                        </div>
                    </div>

                </div>
                <div className="cloud-asset-data">
                    <div>
                        <div>
                            <label>Description:</label>
                        </div>
                        <textarea
                            value={this.state.editingMap.description || asset.description || ''}
                            cols={30}
                            rows={5}
                            name='description'
                            onChange={this.handleChange}
                            className={this.state.editingMap.description ? 'cloud-asset-input-edited' : ''}
                        />
                    </div>
                    <div>
                        <label>Photographer:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.photographer ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="photographer"
                            value={this.state.editingMap.photographer === undefined ? asset.metadata.photographer : this.state.editingMap.photographer}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Landmark:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.landmark ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='landmark'
                            value={this.state.editingMap.landmark === undefined ? asset.metadata.landmark : this.state.editingMap.landmark}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Location:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.location ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="location"
                            value={this.state.editingMap.location === undefined ? asset.metadata.location : this.state.editingMap.location}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Subject:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.subject ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='subject'
                            value={this.state.editingMap.subject === undefined ? asset.metadata.subject : this.state.editingMap.subject}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Categories:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.categories ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='categories'
                            value={this.state.editingMap.categories !== undefined ? this.state.editingMap.categories : asset.metadata.categories || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>File Name:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.fileName ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='fileName'
                            value={this.state.editingMap.fileName || asset.metadata.fileName || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>File Size:</label>
                        <input
                            placeholder='no data..'
                            type="text"
                            name='fileSize'
                            value={this.prettyPrintFileSize(asset.metadata.fileSize) || ''}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label>Dimensions:</label>
                        <input
                            placeholder='no data..'
                            type="text"
                            name='Dimensions'
                            value={(asset.metadata.imageWidth) && asset.metadata.imageHeight ? `${asset.metadata.imageWidth} x ${asset.metadata.imageHeight}` : 'no data..'}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label>From version:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.errorOnFromVersionValidate ? 'error-border' : this.state.editingMap.from_version ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="from_version"
                            value={this.state.editingMap.from_version !== undefined ? this.state.editingMap.from_version : (asset.from_version || '')}
                            onBlur={(e) => this.validateFromVersion(e)}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>To version:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.errorOnToVersionValidate ? 'error-border' : this.state.editingMap.to_version ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="to_version"
                            value={this.state.editingMap.to_version !== undefined ? this.state.editingMap.to_version : (asset.to_version || '')}
                            onBlur={(e) => this.validateToVersion(e)}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Intent:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.intent ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="intent"
                            value={this.state.editingMap.intent !== undefined ? this.state.editingMap.intent : (asset.intent || '')}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Source Data :</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.source_data ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="source_data"
                            value={this.state.editingMap.source_data !== undefined ? this.state.editingMap.source_data : (asset.source_data || '')}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div style={{width: "350px"}}>
                        Intent parameters:
                        <CustomField onCustomFieldUpdate={this.handleIntentParameterChanged}
                                     customField={this.state.editingMap.intent_params || asset.intent_params || {}}/>
                    </div>
                    <div style={{width: "350px"}}>
                        Tags:
                        <ChipGroup
                            editable={true}
                            onNewEntry={(newTag: string) => {
                                this.addNewAnnotation(newTag);
                                tagToScore[newTag] = 1;
                            }}>
                            {
                                Object.keys(tagToScore).map(tag => {
                                    return <Chip key={"tag-"+tag} text={tag} closable={true} onClose={() => {
                                        this.deleteAnnotation(tag)
                                    }}/>
                                })
                            }
                        </ChipGroup>
                    </div>
                    <div style={{width: "350px"}}>
                        Landmarks:
                        <ChipGroup editable={false}>
                            {
                                Object.keys(landmarks).map(landmark => {
                                    return <Chip key={landmark} text={landmark} closable={false} onClose={() => {
                                        this.deleteAnnotation(landmark)
                                    }}/>
                                })
                            }
                        </ChipGroup>

                    </div>
                    <div style={{width: "350px"}}>
                        Captions:
                        <ChipGroup editable={false}>
                            {
                                Object.keys(captions).map(caption => {
                                    return <Chip key={caption} text={caption} closable={false} onClose={() => {
                                        this.deleteAnnotation(caption)
                                    }}/>
                                })
                            }
                        </ChipGroup>

                    </div>
                </div>
            </form>
            <div className="cloud-asset-actions">
                {!this.state.isEditing ?
                    <div></div>
                    :
                    <>
                        <div style={buttonStyle("green", disableSaveBtn)} onClick={(event) => {
                            if (disableSaveBtn)
                                return;

                            this.handleSubmit(event);
                        }}>
                            Save
                        </div>
                        <div style={buttonStyle("red")} onClick={() => {
                            this.cancelChanges();
                        }}>
                            Cancel
                        </div>
                    </>
                }
            </div>
        </div>;
    }

    private deleteAnnotation = (annotation: string) => {
        const asset = this.props.asset;
        this.setState((prevState) => {
            const currState = {...prevState};
            // when a user edits a field we show the save/cancel buttons
            currState.isEditing = true;
            let tags: SQL_CloudAssetTag[] = currState.editingMap.tags || asset?.tags || [];
            tags = tags.filter(t => t.original_description !== annotation);
            currState.editingMap.tags = tags;
            return currState;
        });
    }
}

export default CloudAssetForm;
