/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// tslint:disable:no-import-side-effect
import './res/styles/styles.scss';
import 'react-calendar/dist/Calendar.css';
import {
	ConnectivityModule,
	Thunder,
	ThunderDispatcher,
	ToastModule,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	BaseHttpRequest,
	RequestErrorHandler
} from '@intuitionrobotics/thunderstorm';
import {PermissionsFE} from "@intuitionrobotics/permissions/frontend";
import {Frontend_ModulePack_PushPubSub} from "@intuitionrobotics/push-pub-sub/frontend";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {PROJECT_IDENTITY} from "@app/app-shared/common";
import {BugReportModule} from '@intuitionrobotics/bug-report/frontend';
import {AccountModule} from "@intuitionrobotics/user-account/frontend";
import {MessagesModule} from "@modules/MessagesModule";
import {ChatsModule} from '@modules/ChatsModule';
import {FirebaseModule} from "@intuitionrobotics/firebase/frontend";
import {CommonFEModule} from '@modules/CommonFEModule';
import {TwilioVideoModule} from "@modules/TwilioVideoModule";
import {DataEnvsModule} from "@modules/DataEnvsModule";
import {SupportModule} from '@modules/SupportModule';
import {TaskModuleListener} from "@modules/TaskModule";
import {CallStatusListener} from "@modules/CallStatusListener";
import {CommunityCloudAssetsModule} from "@modules/CommunityCloudAssetsModule";
import {AppModule} from "@modules/AppModule";

export interface OnUnauthenticatedResponse {
	onUnauthenticatedResponse: () => void;
}

XhrHttpModule.addDefaultResponseHandler((request: BaseHttpRequest<any>) => {
	// const functionExecutionId = request?.getResponseHeader?.('function-execution-id')
	// XhrHttpModule.logInfo(`Function execution id: ${functionExecutionId}`)
	if (request.getStatus() !== 401)
		return false;

	new ThunderDispatcher<OnUnauthenticatedResponse, "onUnauthenticatedResponse">("onUnauthenticatedResponse").dispatchUI();
	return true;
});

const modules = [
	AccountModule,
	FirebaseModule,
	CallStatusListener,
	EnvironmentModule,
	BugReportModule,
	ConnectivityModule,
	ToastModule,
	ChatsModule,
	MessagesModule,
	CommonFEModule,
	SupportModule,
	TwilioVideoModule,
	AppModule,
	DataEnvsModule,
	TaskModuleListener,
	CommunityCloudAssetsModule
];

PermissionsFE.setDefaultConfig({projectId: PROJECT_IDENTITY._id});

export const ErrorHandler_Log: RequestErrorHandler<any> = (request, resError?) => {
	XhrHttpModule.logError(request.errorMessage || resError?.debugMessage);
};

export const ThunderInstance = new Thunder()
	.setConfig(require("./config").config)
	.addModules(...Frontend_ModulePack_PushPubSub)
	.addModules(...modules)

