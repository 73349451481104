import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {PARAM_PRODUCT, PARAM_UNIT_ID, PARAM_USER_GROUP} from "../Routes";
import {Unit} from "@app/ir-q-app-common/types/units";
import * as Video from "twilio-video";
import {ObjectTS} from "@intuitionrobotics/ts-common";
import {decode} from "jws";

export const getMediaTag = (mediaType: string, signedUrl: string) => {
	switch (mediaType) {
		case "audio":
		case "audio/amr":
		case "audio/mp3":
			return <audio autoPlay={true} src={signedUrl} controls={true}/>;
		case "image":
		case "image/png":
		case "image/jpg":
		case "image/jpeg":
			return <img style={{maxWidth: 300, maxHeight: 300}} alt={"image"} src={signedUrl}/>;
		case "video":
		case "video/mp4":
			return <video style={{maxWidth: 300, maxHeight: 300}} src={signedUrl} controls={true} autoPlay={false}/>;
		default:
			return `Unsupported media type: ${mediaType}. SignedUrl: ${signedUrl}`;
		}
};

export const isItStandAloneApp = () => {
	const mqStandAlone = '(display-mode: standalone)';
	// @ts-ignore
	if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
		return true;
	}

	return false;
}

export const getSelectedUnit = (unit?: Unit) => {
	const unitId = unit?.unitId || BaseComponent.getQueryParameter(PARAM_UNIT_ID);
	const product = unit?.product || BaseComponent.getQueryParameter(PARAM_PRODUCT);

	if (!unitId || !product)
		return;

	return {unitId, product};
};
export const getUserGroup = (userGroup?: string) => {
	return userGroup || BaseComponent.getQueryParameter(PARAM_USER_GROUP);
};

function doesBrowserSupportWebRTC() {
	// @ts-ignore
	const isWebRTCSupported: undefined | (() => void) = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || window.RTCPeerConnection;
	if (window.navigator.userAgent.indexOf("Edge") > -1)
		return false;

	return !!isWebRTCSupported
}

export function isBrowserReallyUnsupported(){
	if(Video.isSupported)
		return false;

	return !doesBrowserSupportWebRTC()
}

type JWTTokenInformation = {
	exp: number,
	iat: number,
	iss: string,
	session_id: string,
	roles: string[],
	contactId: string,
	permissions: ObjectTS;
	account: {
		email: string,
		_id: string,
	}
}
export const decodeJWT = (token: string) => {
	return decode(token)?.payload as JWTTokenInformation;
}
