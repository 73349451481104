import React from 'react';
import './LoadingSpinner.css';

export type LoadingSpinnerProps = {
    size?: number;
    className?: string;
}

export default function LoadingSpinner(props: LoadingSpinnerProps) {
    const {size = 50, className = ''} = props;
    return (
        <svg className={`spinner${className && ' ' + className}`} viewBox={`0 0 50 50`} width={size} height={size}>
            <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="4"
                strokeDasharray="31.4 31.4"
                strokeDashoffset="0"
            ></circle>
        </svg>
    );
};