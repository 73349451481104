import {BaseComponent, BrowserHistoryModule, GenericSelect, ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {CommonFEModule, OnUnitsLoaded} from "@modules/CommonFEModule";
import {PARAM_PRODUCT, PARAM_UNIT_CLOUD_ASSET_KEY, PARAM_UNIT_ID, PARAM_USER_GROUP} from "../../../Routes";
import {Unit, UnitAndComment} from "@app/ir-q-app-common/types/units";
import {UnitCloudAssetsModule} from "@modules/UnitCloudAssetsModule";
import {getMediaTag} from "../../../utils/utils";
import {Loader} from "../../../../widgets/Loader";
import {Key_MessagingOrigin, Key_PicturePlan, SQL_CloudAssetWithUrl} from "@app/ir-q-app-common/types/cloud-asset";

type State = {
    selectedKey?: string
    selectedUnit?: Unit
    selectedAsset?: SQL_CloudAssetWithUrl
    searchText: string
    dontDelete: { [id: number]: boolean | undefined }
}

export class Playground_ShowCloudAssets extends BaseComponent<{}, State>
    implements OnUnitsLoaded {

    constructor(props: {}) {
        super(props);

        const unitIdParam = BaseComponent.getQueryParameter(PARAM_UNIT_ID);
        const productParam = BaseComponent.getQueryParameter(PARAM_PRODUCT);
        const userGroupParam = BaseComponent.getQueryParameter(PARAM_USER_GROUP);
        const selectedKey = BaseComponent.getQueryParameter(PARAM_UNIT_CLOUD_ASSET_KEY);

        const unit = unitIdParam && productParam ? {unitId: unitIdParam, product: productParam, comment: userGroupParam} : undefined;
        if (unit)
            UnitCloudAssetsModule.queryUnitCloudAssets(unit).finally(() => this.forceUpdate());

        this.state = {
            selectedKey: selectedKey,
            selectedUnit: unit,
            searchText: "",
            dontDelete: {}
        }
    }


    __onUnitsLoaded(): void {
        this.forceUpdate();
    }

    componentWillUnmount() {
        BrowserHistoryModule.removeQueryParam(PARAM_UNIT_ID)
        BrowserHistoryModule.removeQueryParam(PARAM_PRODUCT)
        BrowserHistoryModule.removeQueryParam(PARAM_UNIT_CLOUD_ASSET_KEY)
        BrowserHistoryModule.removeQueryParam(PARAM_USER_GROUP)
    }

    render() {
        return <div style={{width: "100%", height: 700}}>
            <div style={{width: 244, marginTop: 12, marginLeft: 15}}>
                <GenericSelect<UnitAndComment>
                    options={CommonFEModule.getUnits()}
                    selectedOption={this.state.selectedUnit}
                    iconClose={"D"}
                    iconOpen={"U"}
                    onChange={(unit: UnitAndComment) => {
                        BrowserHistoryModule.addQueryParam(PARAM_UNIT_ID, unit.unitId);
                        BrowserHistoryModule.addQueryParam(PARAM_PRODUCT, unit.product);
                        if (unit.comment) {
                            BrowserHistoryModule.addQueryParam(PARAM_USER_GROUP, unit.comment);
                        }

                        if (!UnitCloudAssetsModule.getUnitCloudAssets(unit, this.state.selectedKey))
                            UnitCloudAssetsModule.queryUnitCloudAssets(unit).finally(() => this.forceUpdate());

                        this.setState({selectedUnit: unit, selectedAsset: undefined});
                    }}
                    styles={{}}
                    presentation={option => option.unitId}/>
            </div>
            <div style={{width: 244, marginTop: 12, marginLeft: 15}}>
                <GenericSelect<string>
                    options={UnitCloudAssetsModule.getKeys()}
                    selectedOption={this.state.selectedKey}
                    iconClose={"D"}
                    iconOpen={"U"}
                    onChange={(key: string) => {
                        BrowserHistoryModule.addQueryParam(PARAM_UNIT_CLOUD_ASSET_KEY, key);

                        if (!UnitCloudAssetsModule.getUnitCloudAssets(this.state.selectedUnit, key))
                            UnitCloudAssetsModule.queryUnitCloudAssets(this.state.selectedUnit).finally(() => this.forceUpdate());

                        this.setState({selectedKey: key, selectedAsset: undefined});
                    }}
                    styles={{}}
                    presentation={option => option}/>
            </div>
            <div style={{marginTop: "20px", marginLeft: "50px"}}>
                {this.state.selectedUnit && this.state.selectedKey && this.renderButtons(this.state.selectedUnit, this.state.selectedKey)}
            </div>
            <div style={{display: "flex"}}>
                {this.state.selectedUnit && this.state.selectedKey && this.renderUnitCloudAssets(this.state.selectedUnit, this.state.selectedKey)}
                {this.state.selectedAsset && this.renderAsset(this.state.selectedAsset)}
            </div>
        </div>;
    }

    private renderButtons(unit: Unit, unitCloudAssetKey: string) {
        const unitCloudAssets = UnitCloudAssetsModule.getUnitCloudAssets(unit, unitCloudAssetKey);

        if (!unitCloudAssets)
            return null;

        if (unitCloudAssetKey !== Key_PicturePlan)
            return null;

        return <>
            <button onClick={() => {
                if (!this.state.selectedUnit || !this.state.selectedKey)
                    return;

                UnitCloudAssetsModule.deleteAllOthers(this.state.selectedUnit, this.state.selectedKey, Object.keys(this.state.dontDelete).reduce((carry: number[], key) => {
                    if (this.state.dontDelete[Number(key)])
                        carry.push(Number(key))

                    return carry;
                }, [])).finally(() => this.forceUpdate());
            }}>Delete all besides checks
            </button>
            <button style={{marginLeft: "5px"}} onClick={() => {
                if (!this.state.selectedUnit || !this.state.selectedKey)
                    return;

                UnitCloudAssetsModule.resetUnitPicturePlan(this.state.selectedUnit).finally(() => this.forceUpdate());
            }}>Reset pictures for {this.state.selectedUnit?.unitId}</button>
            <input
                type={"text"}
                value={this.state.searchText}
                onChange={(e) => this.setState({searchText: e.target.value})}
                placeholder={"Search"}
            />
        </>;

    }

    private renderUnitCloudAssets(unit: Unit, unitCloudAssetKey: string) {
        let unitCloudAssets = UnitCloudAssetsModule.getUnitCloudAssets(unit, unitCloudAssetKey);
        if (!unitCloudAssets)
            return <Loader/>

        if (this.state.searchText)
            unitCloudAssets = unitCloudAssets.filter(asset => {
                return Object.entries(asset).some(([k, value]) => {
                    return k !== "agent_id" && typeof value === "string" && value.toLowerCase().includes(this.state.searchText.toLowerCase())
                }) || Object.entries(asset.metadata).some(([k, value]) => {
                    return typeof value === "string" && value.toLowerCase().includes(this.state.searchText.toLowerCase())
                })
            })
        return <ul>{unitCloudAssets.map(asset => {
            const metadata = asset.metadata;
            return <li key={asset.id} style={{cursor: "pointer", color: asset.plan_name === Key_MessagingOrigin ? "green" : "black"}}
                       onClick={() => this.setState({selectedAsset: asset})}>
                <input type="checkbox" checked={!!this.state.dontDelete[asset.id]} onChange={() => {
                    this.setState(s => {
                        const dontDelete = {...s.dontDelete};
                        dontDelete[asset.id] = !dontDelete[asset.id];
                        return {...s, dontDelete}
                    })
                }}/>
                <button style={{color: asset.plan_name === Key_MessagingOrigin ? "green" : "black"}} onClick={() => {
                    if (!this.state.selectedUnit)
                        return ToastModule.toastError("Select unit first");
                    if (!this.state.selectedKey)
                        return ToastModule.toastError("Select key first");

                    UnitCloudAssetsModule.deleteAsset(asset, this.state.selectedUnit, this.state.selectedKey).finally(() => this.forceUpdate());
                }}>X
                </button>
                {`${metadata.promptTitle || asset.description || metadata.subject || unitCloudAssetKey} - ${asset.metadata.typeOfImage}${asset.user_groups ? ` - ${asset.user_groups}` : ""}`}
            </li>
        })}</ul>;
    }

    private renderAsset(asset: SQL_CloudAssetWithUrl) {
        if (!asset.signed_url)
            return null

        if (asset.media_type.toLowerCase() === "audio")
            return <a href={asset.signed_url}>Click to download the asset</a>

        return getMediaTag(asset.media_type.toLowerCase(), asset.signed_url)
    }
}
