import React from "react";
import {Prompt, QueryResponse, TopK} from "./query-model";
import moment from "moment";

interface ResultsProps {
    result?: QueryResponse | null;
    error?: Error | string | null;
}

// Displays the results or the error
export function Results({result, error}: ResultsProps) {

    // Communication error will be an Error object, logic errors from the server-api will be in string
    if (result?.error)
        error = result.error
    return <div className={"Results animate-fade-in"}>
        {error && <div className={'results-error'}>
            {typeof error === 'string' ? <code>ERROR {error}</code> :
                <code>ERROR {error.name}:<br/>${error.message}<br/>${error.stack}`</code>}
        </div>}
        {(!error && result) && <ResultParser result={result}/>}
    </div>

}

interface ResultParserProps {
    result: QueryResponse;
}

interface ScoreProps {
    score: number | undefined
}

const Score = ({score = 0}: ScoreProps) => <span>{(score * 100).toFixed(2)}</span>

// Parse all results to PanelDataView
function ResultParser({result}: ResultParserProps) {
    const [showOmitted, setShowOmitted] = React.useState(false);


    return Object.keys(result.llmResults).map(key => {
        const raw = result.llmResults[key]
        return [<div key={'results-summery'} className={'results-summery'}>
            <div className={'summery-block'}>
                <h3>Results Summery:</h3>
                <div className={'summery-wrapper'}>
                    <div className={'summery-row'}>GPT : <strong>{raw.answer}</strong></div>
                </div>
            </div>
        </div>,
            <div key={'omitted-texts'} className={'omitted-panels'}>
                <button key={'show-omitted-button'} onClick={() => {
                    setShowOmitted(!showOmitted);
                }}>{showOmitted ? 'Hide' : 'Show'} unused texts
                </button>
                {showOmitted && <div key={'omitted-panels'} className={'omitted-list'}>
                    {result.topKs.filteredTopKs.map((topK, i) => {
                        return <div key={'omitted-score-' + i} className={'omitted-panel origin-' + topK.source}>
                            <div className={'omitted-tts'}>{topK.agentText}</div>
                            <div className={'omitted-stt'}>{topK.userText}</div>
                            <div className={'omitted-score'}><Score score={topK.score}/></div>
                        </div>
                    })}
                </div>}
            </div>,
            <div className={'results-panels'} key={'results-panel'}>
                <PanelDataView key={key}
                               serviceName={key}
                               topKs={result.topKs.topKs}
                               prompt={raw.prompt || []}
                               answer={raw.answer}
                               probability={raw.probability}
                               fullAnswer={raw.fullAnswer}/>
            </div>]
    });
}

interface PanelDataViewProps {
    serviceName: string;
    answer: string;
    rawAnswer?: string;
    fullAnswer?: string;
    probability: number;
    topKs: TopK[];
    prompt: Prompt;
}

// Displays a DataView
function PanelDataView({answer, rawAnswer, probability, serviceName, fullAnswer, topKs, prompt}: PanelDataViewProps) {
    const instructions = prompt.find(t => t.role === 'system')?.content;
    let currentRootExecutionId = '';
    return <div className={'results-panel animate-fade-in'}>
        <h3>{serviceName}</h3>
        <p className={'instructions'}>{instructions}</p>
        <h4>Dialogue</h4>
        <div className={'dialogue'}>
            {topKs.map((topK, i) => {
                const newConversation = topK.rootExecutionId !== currentRootExecutionId;
                currentRootExecutionId = topK.rootExecutionId || '';
                const className = newConversation ? 'dialogue-row dialogue-date-changed' : 'dialogue-row';
                const agentDateString = moment(new Date(topK.agentTime * 1000), "MMM DD, YYYY, hh:mm:ss A").toDate().toDateString();
                const userDateString = moment(new Date(topK.userTime * 1000), "MMM DD, YYYY, hh:mm:ss A").toDate().toDateString();
                const agentRow = <>{topK.userText && <div className={'dialogue-agent origin-' + topK.source}>
                    <div className={'entry-date'}>{agentDateString}</div>
                    {topK.agentText}
                    <div className={'entry-score'}>score: <Score score={topK.score}/></div>
                </div>}</>;
                const userRow = <>{topK.userText && <div className={'dialogue-user origin-' + topK.source}>
                    <div className={'entry-date'}>{userDateString}</div>
                    {topK.userText}
                    <div className={'entry-score'}>score: <Score score={topK.score}/></div>
                </div>}</>;
                return [<div key={'dialogue-row-agent' + i} className={className}>
                    {agentRow}
                </div>,
                    <div key={'dialogue-row-user' + i} className={className}>
                        {userRow}
                    </div>]
            })}
        </div>
        <p><strong>Answer : </strong>{answer}</p>
        <p><strong>Raw answer : </strong>{rawAnswer}</p>
        <p><strong>Probability : </strong>{probability}</p>
        {fullAnswer && <p><strong>Full answer : </strong>{fullAnswer}</p>}
    </div>
}

