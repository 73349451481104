import {
    BaseComponent,
    DialogModule,
    GenericSelect,
    ToastModule,
    TS_Checkbox,
    TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import {Loader} from "../../../widgets/Loader";
import * as React from "react";
import {Unit} from "@app/ir-q-app-common/types/units";
import {CloudAssetsModule} from "@modules/CloudAssetsModule";
import * as emotion from "emotion";
import {DataEnvsModule} from "@modules/DataEnvsModule";
import {butterscotch, dark} from "@styles/colors";
import DragnDrop, {_FilesType} from "../ui/file-components/Drag&Drop";
import {UploaderModule} from "@intuitionrobotics/file-upload/frontend";
import {postProcessorImageKey} from "@app/app-shared/media-resource";
import {FileStatus, OnFileStatusChanged} from "@intuitionrobotics/file-upload/shared/modules/BaseUploaderModule";
import {_values, currentTimeMillies, md5, ObjectTS, PartialProperties} from "@intuitionrobotics/ts-common";
import {UnitCloudAssetsModule} from "@modules/UnitCloudAssetsModule";
import {ConfirmDialog} from "../ui/dialogs/ConfirmDialog";
import {ComputerVisionAnnotation} from "@app/ir-q-app-common/types/computer-vision-tags";
import CloudAssetForm, {CAPTIONS_NAME, INTENT_PARAMETERS_NAME, LANDMARKS_NAME, TAGS_NAME} from "./CloudAssetForm";
import {CloudAssetGallery} from "./CloudAssetGallery";
import ImageConfigTable from "./ImageConfigTable";
import {ImageConfigModule} from "@modules/ImageConfigModule";
import {SQL_CloudAsset, SQL_CloudAssetWithUrl} from "@app/ir-q-app-common/types/cloud-asset";

type State = {
    showLoader: boolean,
    selectedKey: string,
    selected: Set<number>,
    queryString: string,
    showOnlySelected: boolean,
    showOnlyHidden: boolean,
    showOnlyNotHidden: boolean,
    showOnlySuggestionCards: boolean,
    sort: "dateOldestFirst" | "dateNewestFirst" | "sizeLargestFirst" | "sizeSmallestFirst" | "",
    files: File[],
    showGallery: boolean,
    showUserGroupPercentages: boolean
}

type Props = {
    unit?: Unit,
}

export class CloudAssetsTable
    extends BaseComponent<Props, State>
    implements OnFileStatusChanged {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedKey: 'picture-plan',
            showLoader: true,
            selected: new Set<number>(),
            queryString: '',
            showOnlySelected: false,
            showOnlyHidden: false,
            showOnlyNotHidden: false,
            showOnlySuggestionCards: false,
            sort: "dateNewestFirst",
            files: [],
            showGallery: false,
            showUserGroupPercentages: false
        };
    }

    componentDidMount(): void {
        DataEnvsModule.query()
        ImageConfigModule.fetchImageConfig().finally(() => this.forceUpdate());
        CloudAssetsModule.fetchUrlsOfImages("picture-plan").finally(() => this.setState({showLoader: false}));
    }

    __onFileStatusChanged = async (feId?: string) => {
        if (!feId)
            return;

        const fileInfo = UploaderModule.getFullFileInfo(feId);
        if (!fileInfo?.tempDoc || fileInfo.status !== FileStatus.PostProcessing)
            return;

        const getHeightAndWidthFromDataUrl = (dataURL: string) => new Promise<{ height: number, width: number }>(resolve => {
            const img = new Image()
            img.onload = () => {
                resolve({
                    height: img.height,
                    width: img.width
                })
            }
            img.src = dataURL
        })

        const file = CloudAssetsModule.getFile(feId);
        const fileAsDataURL = window.URL.createObjectURL(file)
        const imageDimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

        const bucketPointer = {
            path: fileInfo.tempDoc.path,
            bucketName: fileInfo.tempDoc.bucketName
        };

        const cacheId = md5(`${bucketPointer.bucketName}-${bucketPointer.path}`);
        const cloudAsset: PartialProperties<SQL_CloudAsset, "id"> = {
            media_type: "image",
            date_creation: currentTimeMillies(),
            creator: "Upload cloud-asset messaging frontend",
            asset_type: this.state.selectedKey,
            active: false,
            metadata: {
                subject: '',
                location: '',
                photographer: '',
                fileName: file.name,
                fileSize: file.size,
                imageHeight: imageDimensions.height,
                imageWidth: imageDimensions.width,
                typeOfImage: "suggestion_top",
                categories: "suggestion",
                isSuggestion: true
            } as ObjectTS,
            bucket_name: bucketPointer.bucketName,
            bucket_path: bucketPointer.path,
        }
        this.logInfo("creating cloud asset", {cloudAsset})
        CloudAssetsModule.replaceFileInCache(feId, cacheId);
        CloudAssetsModule.create(cloudAsset).finally(() => this.forceUpdate());
    }

    validateFiles = (files: File[]): _FilesType => {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        if (files.length === 0) {
            ToastModule.toastError("no files");
            return {
                accepted: [] as File[],
                rejected: files,
                uploaded: [] as File[]
            };
        }

        const reply: _FilesType = {
            accepted: [],
            rejected: [],
            uploaded: [],
        };
        for (const file of files) {
            const extension = file.name.split('.').pop()?.toLowerCase();
            if (extension && allowedExtensions.includes(extension)) {
                reply.accepted.push(file);
            }
            reply.rejected.push(file);
            ToastModule.toastError(`Rejected file ${file.name}`)
        }
        return reply;
    };

    onAssetSelected = (id: number, prevChecked: boolean): void => {
        this.setState((prevState) => {
            const currState = {...prevState};
            if (prevChecked) {
                currState.selected.delete(id);
            } else {
                currState.selected.add(id);
            }
            return currState;
        })
    }
    onDragAndDrop = async (files: _FilesType) => {
        files.accepted.forEach(file => {
            const [f] = UploaderModule.upload([file], postProcessorImageKey, false)
            CloudAssetsModule.setFileInCache(f.feId, file)
        });
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.files.concat(files.accepted);

            return currState;
        })
        if (files.accepted.length > 0)
            ToastModule.toastSuccess(`Adding ${files.accepted.length} files.`)
    };

    private renderFileInfo(asset: SQL_CloudAssetWithUrl) {
        return <td>
            <div className={emotion.css`
								margin: 5px;
								`}>
									<span className={emotion.css`
										display: block;
									`}>
										<input
                                            className={emotion.css`
												width: 100%;
												text-overflow:ellipsis;`
                                            }
                                            value={asset.bucket_name}
                                            disabled={true}
                                        />
									</span>
                <button
                    onClick={
                        () => {
                            navigator.clipboard
                                .writeText(asset.bucket_name)
                                .then(() => {
                                })
                                .catch((e) => {
                                    this.logError(e);
                                })
                        }
                    }>
                    Copy Text
                </button>
            </div>
            <div className={emotion.css`
								margin: 5px;
								`}>
									<span className={emotion.css`
										display: block;
									`}>
										<input
                                            className={emotion.css`
												width: 100%;
												text-overflow:ellipsis;`
                                            }
                                            value={asset.bucket_path}
                                            disabled={true}
                                        />
									</span>
                <button
                    onClick={
                        () => {
                            navigator.clipboard
                                .writeText(asset.bucket_path)
                                .then(() => {
                                })
                                .catch((e) => {
                                    this.logError(e);
                                })
                        }
                    }>
                    Copy Text
                </button>
            </div>
        </td>

    }

    private renderSearchBar() {
        return <div className={'cloud-asset-search'}>
            <div className={'cloud-asset-search-bar'}>
                <TS_Input
                    id={'search-bar'}
                    type={'text'}
                    placeholder={'search'}
                    value={this.state.queryString}
                    onChange={(value: string) => {
                        this.setState({queryString: value});
                    }}
                />
            </div>
        </div>
    }

    private calculateAssets(assets: SQL_CloudAssetWithUrl[]) {
        return assets.filter((val) => {
            let queryFilter = true;
            if (this.state.queryString) {
                queryFilter = Object.keys(val.metadata).some((key: string) => {
                    const isMatch = (item: string) => item.toString().toLowerCase().match(`${this.state.queryString.toLowerCase()}.*`);
                    if (key === INTENT_PARAMETERS_NAME) {
                        queryFilter = Object.keys(val.metadata[key]).some(isMatch) ||
                            _values(val.metadata[key]).some(isMatch);
                    }

                    if (key === TAGS_NAME || key === LANDMARKS_NAME || key === CAPTIONS_NAME)
                        return val.metadata[key]?.some((annotation: ComputerVisionAnnotation) => isMatch(annotation.description));

                    return isMatch(val.metadata[key]);
                })
            }

            if (!queryFilter)
                return false;

            if (this.state.showOnlySelected) {
                return this.state.selected.has(val.id);
            }
            if (this.state.showOnlyHidden) {
                return !val.active;
            }
            if (this.state.showOnlySuggestionCards) {
                return !!val.metadata.typeOfImage && val.metadata.typeOfImage.startsWith("suggestion");
            }
            return true;
        }).sort(this.getSortingFilter());
    }

    private renderAssetsTable(assets: SQL_CloudAssetWithUrl[]) {
        return <div>
            <div className={emotion.css`
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			`}>
                <table
                    className='cloud-asset-table'
                >
                    <thead className={emotion.css`
						background-color: ${butterscotch};
						border: 2px solid ${dark};
						border-radius: 18px;
					`}>
                    <tr>
                        <th style={{width: '5%'}}></th>
                        <th style={{width: '75%'}}> Information</th>
                        <th style={{width: '20%'}}> Bucket Pointer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {assets.map((asset) => {
                        return <tr key={asset.id}>
                            <td align={"center"}><TS_Checkbox value={asset.id} checked={this.state.selected.has(asset.id)}
                                                              onCheck={this.onAssetSelected} label={""}/></td>
                            <td><CloudAssetForm selectedKey={this.state.selectedKey} asset={asset}/></td>
                            {this.renderFileInfo(asset)}
                        </tr>
                    })}
                    </tbody>

                </table>
            </div>
        </div>
    }

    private renderActionButtons = () => {
        return (
            <div className={'cloud-asset-search-actions'}>
                {
                    this.state.selected.size > 0 ?
                        <>
                            <button className={'cloud-asset-action-button'} style={{backgroundColor: "red"}} onClick={() => {
                                ConfirmDialog.show(
                                    {
                                        okLabel: "Delete",
                                        onOk: () => {
                                            DialogModule.close();
                                            this.deleteSelected();
                                        },
                                        title: "Delete Cloud Assets",
                                        displayMessage: `Are you sure you want to delete selected cloud assets?`
                                    });
                            }}>
                                Delete Selected
                            </button>
                            <button className={'cloud-asset-action-button'} onClick={() => this.changeSelectedIsHiddenStatus(true)}> Hide
                                selected content
                            </button>
                            <button className={'cloud-asset-action-button'} onClick={() => this.changeSelectedIsHiddenStatus(false)}> Unhide
                                selected content
                            </button>
                        </>
                        : null
                }
                <button className={'cloud-asset-action-button'} style={{backgroundColor: "orange"}} onClick={() => {
                    ConfirmDialog.show(
                        {
                            okLabel: "Publish",
                            onOk: () => {
                                DialogModule.close();
                                this.notifyUnits();
                            },
                            title: "Publish to all units ASAP",
                            displayMessage: `Are you sure you want to publish to all units ASAP?`
                        });
                }}>
                    Publish ASAP
                </button>
            </div>
        );
    }

    private renderSorting = () => {
        return (
            <div className={'cloud-asset-search-sorting'}>
                <div className={'cloud-asset-search-actions-left'}>
                    <button
                        className={(this.state.sort === 'dateNewestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
                        onClick={() => this.setState({sort: 'dateNewestFirst'})}>
                        Sort by date newest
                    </button>
                    <button
                        className={(this.state.sort === 'dateOldestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
                        onClick={() => this.setState({sort: 'dateOldestFirst'})}>
                        Sort by date oldest
                    </button>
                    <button
                        className={(this.state.sort === 'sizeLargestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
                        onClick={() => this.setState({sort: 'sizeLargestFirst'})}>
                        Sort by size largest
                    </button>
                    <button
                        className={(this.state.sort === 'sizeSmallestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
                        onClick={() => this.setState({sort: 'sizeSmallestFirst'})}>
                        Sort by size smallest
                    </button>
                    <button
                        className={(this.state.showUserGroupPercentages) ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
                        onClick={() => this.setState(oldState => ({showUserGroupPercentages: !oldState.showUserGroupPercentages}))}>
                        {(this.state.showUserGroupPercentages) ? "Hide group %" : "Show group %"}
                    </button>
                </div>
                <div className={'cloud-asset-search-actions-right'}>
                    <div>
                        <TS_Checkbox
                            value={this.state.showOnlySuggestionCards}
                            checked={this.state.showOnlySuggestionCards}
                            label={'Show only suggestion cards'}
                            onCheck={(_, prevChecked: boolean) => {
                                this.setState({showOnlySuggestionCards: !prevChecked})
                            }}
                        />
                    </div>
                    <div>
                        <TS_Checkbox
                            value={this.state.showOnlySelected}
                            checked={this.state.showOnlySelected}
                            label={'Show only selected'}
                            onCheck={(_, prevChecked: boolean) => {
                                this.setState({showOnlySelected: !prevChecked})
                            }}
                        />
                    </div>
                    <div>
                        <TS_Checkbox
                            value={this.state.showOnlyHidden}
                            checked={this.state.showOnlyHidden}
                            label={'Show only hidden'}
                            onCheck={(_, prevChecked: boolean) => {
                                this.setState({showOnlyHidden: !prevChecked})
                            }}
                        />
                    </div>
                    <div>
                        <TS_Checkbox
                            value={!this.state.showGallery}
                            checked={!this.state.showGallery}
                            label={'List View'}
                            onCheck={(_) => {
                                this.setState({showGallery: false})
                            }}
                        />
                    </div>
                    <div>
                        <TS_Checkbox
                            value={this.state.showGallery}
                            checked={this.state.showGallery}
                            label={'Gallery View'}
                            onCheck={(_) => {
                                this.setState({showGallery: true})
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private deleteSelected = () => {
        if (this.state.selected.size === 0)
            return;

        this.setState({
            showLoader: true,
            showOnlyHidden: false,
            showOnlyNotHidden: false,
            showOnlySelected: false,
            selected: new Set()
        });

        this.state.selected.forEach((id) => {
            const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.state.selectedKey).filter((a) => a.id === id)[0];
            if (!asset)
                return;
            CloudAssetsModule.delete(asset).finally(() => this.setState({showLoader: false}));
        });
        this.setState({selected: new Set()});
    }

    private changeSelectedIsHiddenStatus = (b: boolean) => {
        if (this.state.selected.size === 0)
            return;
        this.setState({
            showLoader: true,
            showOnlyHidden: false,
            showOnlyNotHidden: false,
            showOnlySelected: false,
            selected: new Set()
        });
        this.state.selected.forEach((id) => {
            const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.state.selectedKey).filter((a) => a.id === id)[0];
            if (!asset)
                return;

            asset.active = !b;
            CloudAssetsModule.update(asset).finally(() => this.setState({showLoader: false}));
        });
    }

    private notifyUnits = () => {
        UnitCloudAssetsModule.notifyUnits();
        return;
    }

    render() {
        if (this.state.showLoader)
            return <Loader/>;

        const assets = CloudAssetsModule.getDbAssetsWithImagesByKey(this.state.selectedKey);
        if (!assets.length)
            return <Loader/>;

        const filteredAssets = this.calculateAssets(assets);

        return <div style={{marginTop: 50, height: '100%'}}>
            <DragnDrop onChange={this.onDragAndDrop} validate={this.validateFiles} height={'100%'}>
                <div style={{width: 300}}>
                    <GenericSelect<string>
                        options={["time-capsule-prompt",
                            "picture-plan"]}
                        selectedOption={this.state.selectedKey}
                        iconClose={"D"}
                        iconOpen={"U"}
                        onChange={(key: string) => {
                            this.setState({...this.state, selectedKey: key});
                        }}
                        styles={{}}
                        presentation={option => option}
                    />
                </div>
                {this.renderActionButtons()}
                {this.renderSearchBar()}
                {this.renderSorting()}
                {(this.state.showUserGroupPercentages) && <ImageConfigTable dataEnvs={DataEnvsModule.getItems().map(({label}) => label)}/>}
                {
                    this.state.showGallery ? this.renderGallery(filteredAssets) : this.renderAssetsTable(filteredAssets)
                }
            </DragnDrop>
        </div>;
    }

    private getSortingFilter = () => {
        switch (this.state.sort) {
            case 'dateNewestFirst':
                return (p1: SQL_CloudAssetWithUrl, p2: SQL_CloudAssetWithUrl) => {
                    return p2.date_creation as number - (p1.date_creation as number);
                }
            case 'dateOldestFirst':
                return (p1: SQL_CloudAssetWithUrl, p2: SQL_CloudAssetWithUrl) => {
                    return p1.date_creation as number - (p2.date_creation as number);
                }
            case 'sizeLargestFirst':
                return (p1: SQL_CloudAssetWithUrl, p2: SQL_CloudAssetWithUrl) => {
                    return (p2.metadata.fileSize | 0) - (p1.metadata.fileSize | 0);
                }
            case 'sizeSmallestFirst':
                return (p1: SQL_CloudAssetWithUrl, p2: SQL_CloudAssetWithUrl) => {
                    return (p1.metadata.fileSize | 0) - (p2.metadata.fileSize | 0);
                }
            default:
                return (p1: SQL_CloudAssetWithUrl, p2: SQL_CloudAssetWithUrl) => {
                    return p2.date_creation as number - (p1.date_creation as number);
                }
        }
    }
    private renderGallery = (assets: SQL_CloudAssetWithUrl[]) => {
        return <div style={{margin: "10px"}}>
            <CloudAssetGallery assets={assets} selectedKey={this.state.selectedKey} onSelectImage={(selectedAssets) => {
                this.setState({selected: new Set(selectedAssets)});
            }}/>
        </div>;
    }
}
