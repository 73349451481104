import {Module} from "@intuitionrobotics/ts-common";
import {SQL_ImageConfig} from "@app/ir-q-app-common/types/cloud-asset";
import {ServerApiModule} from "@modules/ServerApiModule";
import {AxiosResponse} from "axios";

export class ImageConfigModule_Class
    extends Module {

    private imageConfig: SQL_ImageConfig[] = [];

    public getImageConfig() {
        return this.imageConfig;
    }

    public fetchImageConfig = async () => {
        const client = await ServerApiModule.getClientWithAuthorization();

        await client
            .get<SQL_ImageConfig[]>(`/asset/get-image-config`)
            .catch(resError => this.logError("Failed to get image config", resError))
            .then(response => {
                this.imageConfig = (response as AxiosResponse<SQL_ImageConfig[]>).data;
            });
    };

    public updateImageConfig = async (imageConfig: SQL_ImageConfig[]) => {
        const imageConfigWithoutDefault = imageConfig.filter(item => item.user_group !== 'default');
        const client = await ServerApiModule.getClientWithAuthorization();

        await client
            .post(`/asset/create-image-configs`, imageConfig)
            .catch(resError => this.logError("Failed to update image config:", resError))
            .then(_ => {
                this.imageConfig = imageConfigWithoutDefault;
            });
    }

}


export const ImageConfigModule = new ImageConfigModule_Class("ImageConfigModule");
