import {ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Response_GetCommunityCloudAsset, SQL_CloudAssetWithUrl} from "@app/ir-q-app-common/types/cloud-asset";
import {currentTimeMillies, Module} from "@intuitionrobotics/ts-common";
import {ServerApiModule} from "@modules/ServerApiModule";
import {AxiosResponse} from "axios";

export class CommunityCloudAssetsModule_Class
	extends Module {
	get dueDateTimestamp() {
		return this._dueDateTimestamp;
	}

	dbAssetsWithImages = (sortFunction?: (a: SQL_CloudAssetWithUrl, b: SQL_CloudAssetWithUrl) => number) => {
		if (sortFunction)
			return this._dbAssetsWithImages?.sort(sortFunction)
		return this._dbAssetsWithImages;
	}

	private _dbAssetsWithImages: SQL_CloudAssetWithUrl[] | undefined;
	private _dueDateTimestamp: number | undefined;

	constructor() {
		super("CommunityCloudAssetsModule");
	}

	public fetchUrlsOfImages = async () => {
		const client = await ServerApiModule.getClientWithAuthorization();

		await client
			.get<Response_GetCommunityCloudAsset>(`/asset/get-community-exhibition`)
			.catch(resError => {
				this.logError("Failed to get community assets:", resError);
				return ToastModule.toastError("Could not fetch community cloud assets from server, please try again.");
			})
			.then(_response => {
				let response = (_response as AxiosResponse<Response_GetCommunityCloudAsset>).data
				this._dbAssetsWithImages = response.assets.map(a => {
					a.asset_type = a.asset_type.toLowerCase().replace(/_/g, "-")
					a.media_type = a.media_type.toLowerCase()
					a.plan_name = a.plan_name?.toLowerCase()
					return a;
				});
				this._dueDateTimestamp = response.timestamp;
			});
	};

	public setSelectedAssets = async (selectedAssets: SQL_CloudAssetWithUrl[], timestamp: number) => {
		const client = await ServerApiModule.getClientWithAuthorization();

		selectedAssets.forEach(asset => asset.media_type = asset.media_type.toUpperCase())
		await client
			.post(`/asset/create-exhibition`, {assets: selectedAssets, timestamp})
			.catch(resError => this.logError("Failed to upload selected assets: ", resError))
			.then(() => {
				this._dueDateTimestamp = timestamp;
				this._dbAssetsWithImages?.forEach((_item: SQL_CloudAssetWithUrl) => {
					let item = {..._item};
					const isUpdatedAsset = selectedAssets.find(a => a.id === item.id);
					if (isUpdatedAsset) {
						item = isUpdatedAsset;
						item.date_publish = currentTimeMillies();
					} else
						item.rate = 0;
				});
			});
	}

	public cancelExhibition = async () => {
		const client = await ServerApiModule.getClientWithAuthorization();

		await client
			.post(`/asset/cancel-exhibition`)
			.catch(resError => this.logError("Failed to upload selected assets: ", resError))
			.then(() => {
				this._dueDateTimestamp = undefined;
				if (this._dbAssetsWithImages)
					this._dbAssetsWithImages.forEach(item => item.rate = 0);
			});
	}


}


export const CommunityCloudAssetsModule = new CommunityCloudAssetsModule_Class();
