import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import Switch from "react-switch";
import React from "react";
import moment from "moment-timezone";
import {Day} from "@intuitionrobotics/ts-common";

type Props = {
	onClick: (_id: number, position: number) => void
	id: number,
	url: string,
	unitId: string,
	selectedItems: number[],
	createdAt: number,
	exhibitionDate?: number
}

type State = {
	selected?: number
}

class CommunityAssetComponent
	extends BaseComponent<Props, State> {
	private itemsArray: any;
	
	constructor(props: Props) {
		super(props);
		
		this.state = {
			selected: undefined
		}
		
		this.itemsArray = [1,
		                   2,
		                   3,
		                   4,
		                   5];
		this.getDate = this.getDate.bind(this);
	}
	
	getDate(timestamp: number) {
		const date = moment.tz(timestamp, 'America/New_York');
		date.set({hours: 23, date: new Date(timestamp).getDate()})
		
		return date;
	}
	
	render() {
		const selectedIndex = this.props.selectedItems.findIndex((item) => item === this.props.id) + 1
		
		return <div style={{display: "flex", flexDirection: 'row', marginBottom: 20}}>
			<img style={{width: 300}} alt={'image'} src={this.props.url}/>
			<div style={{marginLeft: 10, flexDirection: 'column'}}>
				<text>{`unitID: ${this.props.unitId}`}</text>
				<div style={{display: "flex"}}>
					<text>{`created at: ${Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(
						new Date(this.props.createdAt))}`}</text>
				</div>
				{
					this.props.exhibitionDate && <div style={{display: "flex"}}>
						<text>{`Exhibition Date: ${this.getDate(this.props.exhibitionDate - Day).format('DD/MM/YYYY')}`}</text>
					</div>
				}
				{
					this.itemsArray.map((item: number) => {
						return <div key={item} style={{display: "flex", margin: 5, justifyContent: 'flex-start', alignItems: 'center'}}>
							<Switch checked={selectedIndex === item} onChange={() => {
								this.props.onClick(this.props.id, item)
							}}/>
							<text style={{marginLeft: 10}}>{item}</text>
						</div>
					})
				}
			</div>
		</div>
	}
	
}

export default CommunityAssetComponent;
