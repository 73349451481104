import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {DB_Chat} from "@app/ir-q-app-common/types/message";
import {ThunderDispatcher} from "@intuitionrobotics/thunderstorm/frontend";

export interface OnChatQueried {
	__onChatQueried: () => void;
}

export interface OnChatCreated {
	__onChatCreated: (chat: DB_Chat) => void;
}

export const dispatch_onChatCreated = new ThunderDispatcher<OnChatCreated, "__onChatCreated">("__onChatCreated");
export const dispatch_onChatQueried = new ThunderDispatcher<OnChatQueried, "__onChatQueried">("__onChatQueried");

export class ChatsModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_Chat> {

	public callback: (chat: DB_Chat) => void = () => {}
	private chats: DB_Chat[] = [];

	getChats = () => {
		return this.chats;
	}

	async onQueryReturned(items: DB_Chat[]): Promise<void> {
		this.chats = items;
		dispatch_onChatQueried.dispatchUI();
	}

	async onEntryCreated(item: DB_Chat): Promise<void> {
		// dispatch_onChatCreated.dispatchUI(item);
		this.callback(item);
	}
}

export const ChatsModule = new ChatsModule_Class({key: "chats", relativeUrl: "/v1/chat"}, "ChatsModule");
