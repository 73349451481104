import {
	BaseComponent,
	TS_Table
} from "@intuitionrobotics/thunderstorm/frontend";
// import {
// 	PARAM_PRODUCT,
// 	PARAM_UNIT_ID
// } from "../../Routes";
import * as emotion from "emotion";
import {Unit} from "@app/ir-q-app-common/types/units";
import React from "react";
import {Loader} from "../../../../widgets/Loader";
import {DB_Call} from "@app/app-shared/call-status";
import moment from "moment";
import {ContactsModule} from "@modules/ContactsModule";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {sortArray} from "@intuitionrobotics/ts-common";
import {CallStatusListener} from "@modules/CallStatusListener";
import {OnCollectionUpdated} from "@modules/FirebaseListenerModule";

type State = {
	showLoader: boolean
}

type Props = {
	unit?: Unit,
}

type DB_CallExtended = DB_Call & {
	timestampFormat: string,
	participantNames?: string,
	callerName?: string
}

export class VideoCallTable
	extends BaseComponent<Props, State> implements OnCollectionUpdated {

	constructor(props: Props) {
		super(props);

		CallStatusListener.listenImpl();

		this.state = {
			showLoader: true
		};
	}

	__onCollectionUpdated = (collectionName: string) => {
		if (collectionName !== CallStatusListener.getCollectionName())
			return;

		this.setState({showLoader: false});
	}

	componentWillUnmount() {
		CallStatusListener.stopListening();
	}

	private getTsTable() {
		// const selectedUnit = getSelectedUnit(this.props.unit);
		const data = CallStatusListener.getData() as DB_CallExtended[];
		const preparedData: DB_CallExtended[] = sortArray(this.prepareData(data, this.props.unit), item => item.timestamp)

		return <div style={{height: "calc(100vh - 190px)", overflow: "auto"}}>
			<TS_Table<DB_CallExtended>
				className={emotion.css`
				width: 97%;
				margin: 15px;
				border-collapse: collapse;
				position: relative;
				tbody > * > * {
					border: 1px solid black;
					padding: 8px;
				}`}
				id={"video-call-table"}
				header={["_id",
				         "callerName",
				         "callerId",
				         "participantNames",
				         "participants",
				         "roomSid",
				         "status",
				         "timestampFormat",
				         "timestamp"
				]}
				rows={preparedData}
				cellRenderer={cellValue => {
					if (Array.isArray(cellValue))
						return <>{cellValue.join(", ")}</>

					return <>{cellValue}</>
				}}
				headerRenderer={(columnKey: keyof DB_CallExtended) => {
					return <span style={{fontWeight: "bold"}}>{columnKey}</span>;
				}}
			/></div>;
	}

	render() {

		if (this.state.showLoader)
			return <Loader overlay={true}/>

		return <div style={{marginTop: 30, overflow: "hidden"}}>
			{this.getTsTable()}
		</div>;
	}

	private prepareData(data: DB_Call[], unit?: Unit) {
		const contacts = ContactsModule.getItems();

		const unitContact = unit ? contacts.find(
			contact => contact.contactType === "agentUser" && (contact as DB_Contact<"agentUser">).contactData.unitId === unit.unitId) : undefined;

		return data.reduce((carry: DB_CallExtended[], d) => {
			if (unitContact) {
				if (!d.participants.includes(unitContact._id))
					return carry
			}

			const participants = contacts.filter(contact => d.participants.includes(contact._id))
			const caller = participants.find(p => p._id === d.callerId)

			carry.push({
				           ...d,
				           timestampFormat: moment(d.timestamp).format("hh:mm:ss A DD-MM-YYYY"),
				           participantNames: participants.map(participant => `${participant.firstName} ${participant.lastName}`).join(", "),
				           callerName: caller ? `${caller.firstName} ${caller.lastName}` : undefined
			           })
			return carry
		}, [])
	}
}
