import {Module} from "@intuitionrobotics/ts-common";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {AccountModule, LoggedStatus, OnLoginStatusUpdated} from "@intuitionrobotics/user-account/frontend";
import {ThunderDispatcher, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {AgentList} from "@app/ir-q-app-common/types/api";
import {UnitAndComment} from "@app/ir-q-app-common/types/units";


export interface OnUnitsLoaded {
    __onUnitsLoaded: () => void;
}

const dispatch_onUnitsLoaded = new ThunderDispatcher<OnUnitsLoaded, "__onUnitsLoaded">("__onUnitsLoaded");

export class ProductsFEModule_Class
    extends Module<{}>
    implements OnLoginStatusUpdated {
    private units: UnitAndComment[] = [];

    private queryConsumers: (() => void)[] = [];

    onLoginStatusUpdated() {
        if (AccountModule.getLoggedStatus() !== LoggedStatus.LOGGED_IN)
            return;

        this.fetchUnits();
    }

    async getAllOrWait() {
        const units = this.getUnits();
        if (units.length)
            return units;

        return new Promise<UnitAndComment[]>(resolve => this.queryConsumers.push(() => resolve(this.getUnits())));
    }

    getUnits = () => {
        return this.units;
    };

    fetchUnits = () => {
        XhrHttpModule
            .createRequest<AgentList>(HttpMethod.GET, "list-units")
            .setRelativeUrl("/v2/unit/list")
            .setUrlParam("includeComment", "true")
            .setOnError("error fetching Ui units")
            .execute((response => {
                this.units = response.units;
                this.queryConsumers.forEach(c => c());
                dispatch_onUnitsLoaded.dispatchUI();
                dispatch_onUnitsLoaded.dispatchModule();
            }));
    };

}

export const CommonFEModule = new ProductsFEModule_Class("ProductsFEModule");
