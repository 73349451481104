import React from 'react';
import * as emotion from 'emotion';
import {BaseComponent, Dialog_Builder} from "@intuitionrobotics/thunderstorm/frontend";
import CloudAssetForm from "./CloudAssetForm";
import {SQL_CloudAssetWithUrl} from "@app/ir-q-app-common/types/cloud-asset";

// convert the above css to emotion css
const gallery = emotion.css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
`


const imageContainer = emotion.css`
    position: relative;
`

const checkboxContainer = emotion.css`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  &:hover {
  background-color: #eee;
  &.checked {
       background-color: #4CAF50;
      border-color: #4CAF50;
      color: #fff;
      
  }
  &:hover {
    background-color: #3e8e41;
  }
}
`

const imageWrapper = emotion.css`
  width: 100%;
  flex-basis: calc((100% - 15px) / 3);
  height: 400px;
  overflow: hidden;
  border: 2px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.2s ease-in-out;
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

// make it extens the React.Component class
// put the props in a separate interface
export interface CloudAssetGalleryProps {
    assets: SQL_CloudAssetWithUrl[];
    selectedKey: string;
    onSelectImage: (selectedImages: number[]) => void
}

interface CloudAssetGalleryState {
    selectedAssets: number[]
}

export class CloudAssetGallery extends BaseComponent<CloudAssetGalleryProps, CloudAssetGalleryState> {
    constructor(props: CloudAssetGalleryProps) {
        super(props)
        this.state = {
            selectedAssets: []
        }
    }

    public static show(asset: SQL_CloudAssetWithUrl, selectedKey: string) {
        new Dialog_Builder(
            <CloudAssetForm asset={asset} selectedKey={selectedKey}/>)
            .setAllowIndirectClosing(true)
            .setStyle({width: "90%", height: "75%", overflowY: "auto"})
            .show();
    }

    handleImageClick = (asset: SQL_CloudAssetWithUrl) => {
        CloudAssetGallery.show(asset, this.props.selectedKey);
    }

    handleCheckboxClick = (assetId: number) => {
        const {selectedAssets} = this.state
        const {onSelectImage} = this.props

        if (selectedAssets.includes(assetId)) {
            this.setState(
                (prevState) => {
                    return {selectedAssets: prevState.selectedAssets.filter((aId) => aId !== assetId)}
                },
                () => {
                    onSelectImage(this.state.selectedAssets)
                }
            )
        } else {
            this.setState(
                (prevState) => {
                    return {selectedAssets: [...prevState.selectedAssets, assetId]}
                },
                () => {
                    onSelectImage(this.state.selectedAssets)
                }
            )
        }
    }

    render() {
        return (
            <div className={gallery}>
                {
                    this.props.assets.map((asset: SQL_CloudAssetWithUrl) => {
                        const imageUrl = asset.signed_url;

                        return (
                            <div className={imageContainer} key={asset.id}>
                                <div className={checkboxContainer} onClick={() => this.handleCheckboxClick(asset.id)}>
                                    <input
                                        type="checkbox"
                                        checked={this.state.selectedAssets.includes(asset.id)}
                                        onChange={() => {
                                        }}
                                    />
                                </div>
                                <div className={imageWrapper} onClick={() => this.handleImageClick(asset)}>
                                    <img src={imageUrl} alt="Gallery Image"/>
                                </div>
                            </div>)
                    })
                }
            </div>
        );
    };
}
