import {currentTimeMillies, Minute, Module, Second} from "@intuitionrobotics/ts-common";
import {config} from "../config";
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {decodeJWT} from "../app/utils/utils";
import axios, {AxiosInstance} from "axios";

export class ServerApiModule_Class
    extends Module {

    private readonly client!: AxiosInstance;

    constructor(name: string) {
        super(name);

        this.client = axios.create({
            baseURL: `${config.ServerApiModule.serverApiUrl}`,
            timeout: Minute,
            headers: {"Content-Type": "application/json"}
        });

        this.client.interceptors.request.use(async (axiosConfig) => {
            const callAuth = axiosConfig.headers.get("Authorization");
            if (!callAuth) {
                let token = localStorage.getItem("tomcat-jwt");
                if (token == null || decodeJWT(token).exp < (currentTimeMillies() - 10 * Second)) {
                    token = await XhrHttpModule.createRequest<any>(HttpMethod.GET, 'get token')
                        .setRelativeUrl(`/v1/account/jwt`)
                        .executeSync()

                    localStorage.setItem("tomcat-jwt", token as string)
                }

                axiosConfig.headers.Authorization = 'Bearer ' + token
            }
            return axiosConfig
        });

        this.client.interceptors.response.use((axiosConfig) => {
            const responseHeader = axiosConfig.headers["Authorization"];
            if (!responseHeader)
                return axiosConfig;

            localStorage.setItem("tomcat-jwt", responseHeader.replace("Bearer ", ""))
            return axiosConfig;
        });
    }

    public getClientWithAuthorization = async () => {
        return this.client;
    }
}

export const ServerApiModule = new ServerApiModule_Class("ServerApiModule");
