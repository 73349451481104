import {ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Unit, UnitAndComment} from "@app/ir-q-app-common/types/units";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {Api_NotifyUnitsToReloadPictures} from "@app/app-shared/cloud-asset-api";
import {Key_MemoirRecord, Key_PicturePlan, Key_TimeCapsuleRecord, SQL_CloudAssetWithUrl} from "@app/ir-q-app-common/types/cloud-asset";
import {Module, ObjectTS} from "@intuitionrobotics/ts-common";
import {ServerApiModule} from "@modules/ServerApiModule";
import {AxiosResponse} from "axios";

export const Key_GetCloudAssetsPerUnit = "get-unit-cloud-assets";

export class UnitCloudAssetsModule_Class
    extends Module {
    private cloudAssetsPerUnit: { [key: string]: { [key: string]: SQL_CloudAssetWithUrl[] } } = {};

    async queryUnitCloudAssets(unit?: UnitAndComment) {
        if (!unit)
            return

        const client = await ServerApiModule.getClientWithAuthorization();

        await client
            .get<SQL_CloudAssetWithUrl[]>(`/asset/get-all/${unit.unitId}/${unit.comment || " "}`)
            .catch(() => ToastModule.toastError("Could not fetch unit cloud assets"))
            .then(response => {
                this.cloudAssetsPerUnit[unit.unitId] = {};

                (response as AxiosResponse<SQL_CloudAssetWithUrl[]>).data.forEach(asset => {
                    asset.asset_type = asset.asset_type.toLowerCase().replace(/_/g, "-")
                    asset.media_type = asset.media_type.toLowerCase()
                    asset.plan_name = asset.plan_name?.toLowerCase()

                    if (!this.cloudAssetsPerUnit[unit.unitId][asset.asset_type])
                        this.cloudAssetsPerUnit[unit.unitId][asset.asset_type] = []
                    this.cloudAssetsPerUnit[unit.unitId][asset.asset_type] = [...this.cloudAssetsPerUnit[unit.unitId][asset.asset_type], asset]
                });
            })
    }

    async deleteAllOthers(unit: Unit, asset_type: string, dontDelete: number[]) {
        const client = await ServerApiModule.getClientWithAuthorization();

        await client
            .post(`/asset/delete-all-others/${unit.unitId}`, {unit, idsToKeep: dontDelete})
            .catch(() => ToastModule.toastError("Could not delete unit cloud assets"))
            .then(() => {
                this.cloudAssetsPerUnit[unit.unitId][asset_type] = this.cloudAssetsPerUnit[unit.unitId][asset_type].filter(asset =>
                    dontDelete.find(dontDeleteId => dontDeleteId === asset.id));
            })
    }

    async resetUnitPicturePlan(unit: Unit) {
        const client = await ServerApiModule.getClientWithAuthorization();

        await client
            .post(`/asset/reset/${unit.unitId}`)
            .catch(() => ToastModule.toastError("Could not reset unit picture plan"))
            .then(() => this.cloudAssetsPerUnit[unit.unitId][Key_PicturePlan] = [])
    }

    getKeys() {
        return [Key_TimeCapsuleRecord, Key_PicturePlan, Key_MemoirRecord]
    }

    getUnitCloudAssets(unit?: Unit, unitCloudAssetKey?: string) {
        if (!unit || !unitCloudAssetKey)
            return;

        if (!this.cloudAssetsPerUnit || !this.cloudAssetsPerUnit[unit.unitId] || !this.cloudAssetsPerUnit[unit.unitId][unitCloudAssetKey])
            return;

        return this.cloudAssetsPerUnit[unit.unitId][unitCloudAssetKey];
    }

    public deleteAsset = async (item: SQL_CloudAssetWithUrl, unit: Unit, assetKey: string) => {
        const client = await ServerApiModule.getClientWithAuthorization();
        const token = await XhrHttpModule.createRequest<any>(HttpMethod.GET, 'get token')
            .setRelativeUrl(`/v1/account/jwt`)
            .setUrlParams({unitId: unit.unitId})
            .executeSync()
        await client
            .post(`/asset/delete/${unit.unitId}`, {id: item.id, unit, key: assetKey}, {
                headers: {
                    "Authorization": "Bearer " + token,
                }
            })
            .catch(resError => this.logError("Failed to delete cloud asset:", resError))
            .then(_ => {
                const agentId = item.agent_id as string;
                this.cloudAssetsPerUnit[agentId][item.asset_type] = this.cloudAssetsPerUnit[agentId][item.asset_type].filter(asset => asset.id !== item.id)
            });
    };

    public notifyUnits = (payload: ObjectTS = {}) => {
        XhrHttpModule
            .createRequest<Api_NotifyUnitsToReloadPictures>(HttpMethod.POST, "notify-units-to-reload-pictures")
            .setRelativeUrl("/v1/cloud-asset/notify-units-to-reload-pictures")
            .setJsonBody({payload})
            .setOnError(resError => {
                ToastModule.toastError("Failed to notify units");
                this.logError("Failed to notify units:", resError);
            })
            .execute((async _ => {
                ToastModule.toastSuccess("Notified units");
            }));
    };

}

export const UnitCloudAssetsModule = new UnitCloudAssetsModule_Class("UnitCloudAssetsModule");
