import * as React from "react";
import {MessagesModule} from "@modules/MessagesModule";
import {Loader} from "../../../../widgets/Loader";
import {plusSign} from "../../ui/file-components/css/file-uploader";
import {
	BaseComponent,
	ToastModule
} from "@intuitionrobotics/thunderstorm/frontend";
import DragnDrop, {_FilesType} from "../../ui/file-components/Drag&Drop";
import {ContactsModule} from "@modules/ContactsModule";
import {Unit} from "@app/ir-q-app-common/types/units";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {Playground_ParticipantsForm} from "./Playground_ParticipantsForm";
import {AudioWavePlayer} from "../../../../widgets/speech-audio/AudioWavePlayer";

const MicRecorder = require('mic-recorder-to-mp3');

const recorder = new MicRecorder({
	                                 bitRate: 128,
	                                 sampleRate: 44100, // default is 44100, it can also be set to 16000 and 8000.
                                 });

type State = {
	message: { file?: File, filePrefix?: number[], text: string },
	unitContact: string,
	isFromUnitToContact: boolean,
	isSendingImage: boolean,
	selectedUnit?: Unit,
	userContact?: DB_Contact<any>,
	loading: boolean,
	fileId: string
	fileName: string
	isBlocked: boolean
	isRecording: boolean
	isPlaying: boolean
}

export class Playground_SendMmsMessageWholeFlow
	extends BaseComponent<{}, State> {

	constructor(props: {}) {
		super(props);
		this.state = {
			isFromUnitToContact: true,
			isSendingImage: true,
			message: {text: ""},
			unitContact: "",
			loading: false,
			fileId: "",
			fileName: "",
			isBlocked: false,
			isRecording: false,
			isPlaying: false
		};
	}

	componentDidMount(): void {
		ContactsModule.query();

		navigator.mediaDevices.getUserMedia({audio: true}).then(() => {
			this.logInfo('Permission Granted');
			this.setState({isBlocked: false});
		}).catch(() => {
			this.logError('Permission Denied');
			this.setState({isBlocked: true});
		})
	}

	private inputRef = React.createRef<HTMLInputElement>();

	loading = () => {
		return this.state.loading ? <Loader/> : null;
	};

	private validateFiles = (files: File[]): _FilesType => {
		const failReply = {
			accepted: [] as File[],
			rejected: files,
			uploaded: [] as File[]
		};
		if (files.length === 0) {
			ToastModule.toastError("no files");
			return failReply;
		}
		if (files.length > 1) {
			ToastModule.toastError("more than one file");
			return failReply;
		}
		// const file = files[0];
		// if (!file.name.endsWith(".json")) {
		// 	ToastModule.toastError("file is not a json");
		// 	return failReply;
		// }

		return {
			accepted: files,
			rejected: [] as File[],
			uploaded: [] as File[]
		};
	};

	private onDragAndDrop = async (files: _FilesType) => {
		const file = files.accepted[0];
		const reader = await file.arrayBuffer();
		const filePrefix = Array.from(new Uint8Array(reader).subarray(0, 100));
		this.setState({fileName: file.name, message: {...this.state.message, filePrefix: filePrefix, file}});
	};

	private onSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files: _FilesType = this.validateFiles(Object.values(e.target.files));
			await this.onDragAndDrop(files);
		}
	};

	private renderDragAndDrop = () => <DragnDrop
		validate={this.validateFiles}
		error={undefined}
		onChange={this.onDragAndDrop}
		height={'unset'}
	>
		<div
			onClick={() => this.inputRef.current?.click()}
			className={'ll_h_c match_height'}
			style={{justifyContent: 'center'}}
		>
			<div className={plusSign}>
				<input id="fileInput" type="file" ref={this.inputRef} hidden={true} onChange={this.onSelect}/>
			</div>
		</div>
	</DragnDrop>;

	private startRecording = () =>
		recorder.start().then(() => {
			this.setState({isRecording: true});
		}).catch((e: Error) => {
			ToastModule.toastInfo("You need to grant microphone permission");
			this.logError(e);
		});

	private stopRecording = () =>
		// Once you are done singing your best song, stop and get the mp3.
		recorder
			.stop()
			.getMp3()
			.then(async ([buffer, blob]: [BlobPart[], Blob]) => {
				// do what ever you want with buffer and blob
				// Example: Create a mp3 file and play
				const file = new File(buffer, 'audio.mp3', {
					type: blob.type,
					lastModified: Date.now()
				});

				const reader = await file.arrayBuffer();
				const filePrefix = Array.from(new Uint8Array(reader).subarray(0, 100));
				this.setState({
					              isRecording: false,
					              fileName: file.name,
					              message: {...this.state.message, filePrefix, file}
				              });
				this.forceUpdate();

			}).catch((e: Error) => {
			alert('We could not retrieve your message');
			console.log(e);
		});

	private playRecording = async () => {
		const player = new Audio(URL.createObjectURL(this.state.message.file as File));
		await player.play();

		player.onended = () => this.setState({isPlaying: false});
	};

	private renderAudioButtons = () =>
		<section style={{display: "flex"}}>
			<button onClick={() => this.startRecording()} disabled={this.state.isRecording || this.state.isPlaying}>Start</button>
			<button style={{marginRight: "5mm"}} onClick={() => this.stopRecording()} disabled={!this.state.isRecording}>Stop</button>
			{this.state.isRecording ?
				<img src="https://img.icons8.com/ios/50/000000/audio-wave--v1.png"/> :
				<button onClick={async () => {
					this.setState({isPlaying: true});
					await this.playRecording();
				}} disabled={this.state.isPlaying || this.state.isRecording || this.state.message.file?.type !== "audio/mp3"}>Play</button>
			}
			{
				// TODO: If I don't add the "!this.state.isRecording" clause, the previous AudioWavePlayer
				// is not unmounted, so the audio file it has loaded is not updated. We need to investigate this further.
				// blob prop has priority.
				this.state.message.file && !this.state.isRecording && <AudioWavePlayer
					url={""}
					blob={this.state.message.file}
				/>
			}
		</section>;

	render() {
		return <div style={{width: 400}}>
			<h3>Send MMS Message</h3>
			<section style={{display: "flex", marginTop: "5mm", marginBottom: "5mm"}}>
				<div className="form-check" style={{flex: "1"}}>
					<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked
					       onClick={() => this.setState({isSendingImage: true})}/>
					<label className="form-check-label" htmlFor="flexRadioDefault1">
						Image
					</label>
				</div>
				<div className="form-check">
					<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
					       onClick={() => this.setState({isSendingImage: false})}/>
					<label className="form-check-label" htmlFor="flexRadioDefault2">
						Audio
					</label>
				</div>
			</section>
			{this.state.isSendingImage ? this.renderDragAndDrop() : this.renderAudioButtons()}
			<div style={{marginBottom: "5px"}}>
				{this.state.fileName}
			</div>
			{/*<div style={{marginBottom: "5px"}}>*/}
			{/*	<label>Message Body:</label>*/}
			{/*	<input value={this.state.message.text} onChange={(event) => {*/}
			{/*		this.setState({message: {...this.state.message, text: event.target.value}});*/}
			{/*	}} type="text" style={{border: "1px solid gray", display: "block"}} />*/}
			{/*</div>*/}
			<Playground_ParticipantsForm userContact={this.state.userContact}
			                             selectedUnit={this.state.selectedUnit}
			                             fromUnitToContact={this.state.isFromUnitToContact}
			                             setMessageDirection={() => this.setState({
				                                                                      isFromUnitToContact: !this.state.isFromUnitToContact
			                                                                      })}
			                             setUnitContact={(unit, unitContact) =>
				                             this.setState({selectedUnit: unit, unitContact: unitContact._id})}
			                             setUserContact={(contact) => this.setState({userContact: contact})}/>
			<button disabled={!this.state.message.file || !this.state.userContact || !this.state.unitContact}
			        onClick={async () => {
				        this.setState({loading: true});
				        if (!this.state.userContact)
					        return ToastModule.toastError("No contact selected");

				        if (!this.state.message.file)
					        return ToastModule.toastError("No file selected");

				        if (!this.state.message.filePrefix)
					        return ToastModule.toastError("No file selected");

				        await MessagesModule.sendMediaMessage({
					                                              senderId: this.state.isFromUnitToContact ? this.state.unitContact : this.state.userContact?._id,
					                                              contactIds: [this.state.isFromUnitToContact ? this.state.userContact?._id : this.state.unitContact],
					                                              filePrefix: this.state.message.filePrefix
				                                              }, this.state.message?.file);

				        this.setState({loading: false, fileName: "", message: {file: undefined, text: ""}});
			        }}>Send
			</button>
			{this.loading()}
		</div>;
	}

}
