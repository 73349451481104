import {BaseComponent, DialogModule, ToastModule,} from "@intuitionrobotics/thunderstorm/frontend";
import {CommunityCloudAssetsModule} from "@modules/CommunityCloudAssetsModule";
import React from "react";
import {Loader} from "../../../../widgets/Loader";
import CommunityAssetComponent from "../../general-cloud-assets/CommunityAssetComponent";
import Calendar from 'react-calendar';

import '@styles/calendar.scss';
import {ConfirmDialog} from "../../ui/dialogs/ConfirmDialog";
import moment from "moment-timezone";
import {Day} from "@intuitionrobotics/ts-common";
import {SQL_CloudAssetWithUrl} from "@app/ir-q-app-common/types/cloud-asset";

type State = {
    showLoader: boolean,
    selectedRates: number[],
    date?: moment.Moment,
    showCalendar: boolean,
    showPopup: boolean,
    popupPosition: { x: number, y: number }
    isActiveExhibition: boolean
}

export class CommunityCloudAssetTable
    extends BaseComponent<{}, State> {
    private readonly popupRef: React.RefObject<any>;
    private readonly calendarRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.state = {
            isActiveExhibition: false,
            showLoader: true,
            selectedRates: new Array(5),
            date: undefined,
            showCalendar: false,
            showPopup: false,
            popupPosition: {x: 0, y: 0},
        };

        this.popupRef = React.createRef();
        this.calendarRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onCancelExhibition = this.onCancelExhibition.bind(this);
        this.handleClickCalendar = this.handleClickOutsideCalendar.bind(this);
        this.handleClickOutsideCalendar = this.handleClickOutsideCalendar.bind(this);
        this.getDate = this.getDate.bind(this);
    }

    handleClick(event: { clientX: any; clientY: any; }) {
        const popupPosition = {x: event.clientX, y: event.clientY};
        this.setState({
            showPopup: true,
            popupPosition: popupPosition,
        });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    onCancelExhibition() {
        ConfirmDialog.show(
            {
                okLabel: "Stop",
                onOk: () => {
                    DialogModule.close();
                    this.setState({showLoader: true, isActiveExhibition: false, showPopup: false});
                    CommunityCloudAssetsModule.cancelExhibition().finally(() => this.initState())
                },
                title: "Stop current exhibition",
                displayMessage: `Are you sure you want to stop the current exhibition?`
            });
    }

    getDate(timestamp: number) {
        const currentDate = new Date(timestamp)
        const date = moment.tz(timestamp, 'America/New_York');
        date.set({hours: 23, date: currentDate.getDate(), month: currentDate.getMonth()})

        return date;
    }

    handleClickOutside(event: { target: any; }) {
        if (this.popupRef.current && !this.popupRef.current.contains(event.target) && this.state.showPopup) {
            this.setState({showPopup: false});
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickCalendar(event: { target: any; }) {
        this.setState({
            showCalendar: true,
        });
        document.addEventListener('mousedown', this.handleClickOutsideCalendar);
    }

    handleClickOutsideCalendar(event: { target: any; }) {
        if (this.calendarRef.current && !this.calendarRef.current.contains(event.target) && this.state.showCalendar) {
            this.setState({showCalendar: false});
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentDidMount() {
        CommunityCloudAssetsModule.fetchUrlsOfImages().finally(() => this.initState());

        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutsideCalendar);
    }

    private initState() {
        const data = CommunityCloudAssetsModule.dbAssetsWithImages();
        const timestamp = CommunityCloudAssetsModule.dueDateTimestamp;
        const array = new Array(5);

        data?.forEach(item => {
            if (item.rate !== undefined) {
                array[item.rate - 1] = item.id;
            }
        })

        this.setState(oldState => {
            return {
                showLoader: false,
                showCalendar: false,
                showPopup: false,
                popupPosition: {x: 0, y: 0},
                selectedRates: array,
                timestamp: undefined,
                isActiveExhibition: !!timestamp && new Date(timestamp as number).getTime() > new Date().getTime(),
                date: !!timestamp ? this.getDate(timestamp - Day) : undefined
            };
        });
    }

    onClick = (id: number, position: number) => {
        let copiedArray = [...this.state.selectedRates];

        if (copiedArray[position - 1] === id)
            copiedArray[position - 1] = 0;
        else {
            copiedArray.forEach((item, index) => {
                if (item === id)
                    return copiedArray[index] = 0;
            });

            copiedArray[position - 1] = id;
        }

        this.setState({selectedRates: copiedArray})
    }

    sortFunction = (a: SQL_CloudAssetWithUrl, b: SQL_CloudAssetWithUrl) => {
        if (this.state.selectedRates.includes(a.id) && this.state.selectedRates.includes(b.id)) {
            return this.state.selectedRates.indexOf(a.id) - this.state.selectedRates.indexOf(b.id);
        } else if (this.state.selectedRates.includes(b.id))
            return 1;
        else if (this.state.selectedRates.includes(a.id))
            return -1;
        else
            return b.date_creation as number - (a.date_creation as number);
    }

    validateSelection = () => {
        if (this.state.date === undefined) {
            return ToastModule.toastError("You haven't selected a date, please select one");
        }

        if (this.state.selectedRates.filter((item) => item === undefined || item === 0).length > 0)
            return ToastModule.toastError("You have selected less than 5 images, please check your selections");
        else if (new Date().getTime() > this.state.date?.valueOf())
            return ToastModule.toastError("Selected date can not be in the past");

        const data = CommunityCloudAssetsModule.dbAssetsWithImages();

        ConfirmDialog.show(
            {
                okLabel: "Upload",
                onOk: () => {
                    DialogModule.close();

                    const selectedAssets: SQL_CloudAssetWithUrl[] = [];

                    for (const [index, item] of this.state.selectedRates.entries()) {
                        const asset = data?.find((_item) => _item.id === item);

                        if (asset === undefined)
                            return ToastModule.toastError("There was an error on the items you selected please try again");

                        asset.rate = index + 1;
                        asset.active = true;
                        selectedAssets.push(asset)
                    }

                    if (this.state.selectedRates.filter((item) => item === undefined || item === 0).length > 0)
                        return ToastModule.toastError("You have selected less than 5 images, please check your selections");
                    else if (this.state.date) {
                        this.setState({showLoader: true});
                        CommunityCloudAssetsModule.setSelectedAssets(selectedAssets, this.state.date?.valueOf()).finally(() => {
                            const timestamp = CommunityCloudAssetsModule.dueDateTimestamp;
                            const array = new Array(5);

                            selectedAssets.forEach((item) => {
                                if (item.rate !== undefined) {
                                    array[item.rate - 1] = item.id;
                                }
                            })

                            this.setState(() => {
                                return {
                                    showLoader: false,
                                    showPopup: false,
                                    showCalendar: false,
                                    popupPosition: {x: 0, y: 0},
                                    selectedRates: array,
                                    timestamp: undefined,
                                    isActiveExhibition: !!timestamp && new Date(timestamp as number).getTime() > new Date().getTime(),
                                    date: !!timestamp ? this.getDate(timestamp - Day) : undefined
                                };
                            });
                        });
                    }
                },
                title: "Upload community cloud assets",
                displayMessage: `Would you like to publish the selected images until the ${this.state.date && this.state.date.format('DD/MM/YYYY')}?`
            });
    }

    clearSelection = () => {
        this.setState({selectedRates: new Array(5)});
    }

    getDateForCalendar(date: moment.Moment | undefined) {
        if (!date)
            return new Date();

        return new Date(date.year(), date.month(), date.date())
    }

    render() {
        if (this.state.showLoader)
            return <Loader/>

        const data = CommunityCloudAssetsModule?.dbAssetsWithImages()?.sort(this.sortFunction);
        return <div style={{width: '98vw'}}>
            <text style={{fontWeight: '400', fontSize: 14}}>Last day of the exhibition</text>
            <div style={{display: 'flex', flexDirection: 'row', height: 60}}>
                <div style={{
                    flexDirection: 'row',
                    width: 700,
                    borderRadius: 5,
                    borderColor: '#53BAD5',
                    borderStyle: "solid",
                    marginBottom: 20,
                    marginTop: 5,
                    padding: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({showCalendar: !this.state.showCalendar})
                }}>
                    <text style={{}}>{this.state.date && this.state.date.format('DD/MM/YYYY')}</text>
                    <img style={{width: 18, height: 18}} alt={'image'} src={require("@res/icons/icon__calendar.png")}/>
                </div>
                <div style={{
                    width: 120,
                    padding: 10,
                    backgroundColor: '#53BAD5',
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginBottom: 20,
                    marginTop: 5,
                    marginLeft: 10,
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }} onClick={this.clearSelection}>
                    <text style={{color: 'white'}}>Clear Selection</text>
                </div>
                <div style={{
                    width: 120,
                    padding: 10,
                    marginBottom: 20,
                    marginTop: 5,
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <text style={{color: this.state.isActiveExhibition ? 'green' : 'red'}}>
                        {
                            this.state.isActiveExhibition ? "Running" : "Not Running"
                        }
                    </text>
                    <img style={{width: 18, height: 18, cursor: 'pointer'}} onClick={this.handleClick}
                         ref={this.popupRef} alt={'image'}
                         src={require("@res/icons/icon__button.svg")}/>
                    {
                        this.state.showPopup && <div ref={this.popupRef} onClick={this.onCancelExhibition} style={{
                            position: 'absolute',
                            padding: 10,
                            top: this.state.popupPosition.y - 50,
                            left: this.state.popupPosition.x - 50,
                            background: 'white',
                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}>
                            <img style={{width: 36, height: 36, cursor: 'pointer', marginRight: 10}} alt={'image'}
                                 src={require("@res/icons/icon__stop.svg")}/>
                            <text>Stop the exhibition</text>
                        </div>

                    }
                </div>
            </div>
            {this.state.showCalendar &&
                <div ref={this.calendarRef} style={{
                    position: 'absolute',
                    left: 500,
                    backgroundColor: 'white',
                    padding: 15,
                    borderStyle: 'solid'
                }}>
                    <Calendar onChange={(value) => {
                        // @ts-ignore
                        const date = new Date(value)
                        this.setState({date: this.getDate(date.getTime())})
                    }} value={this.getDateForCalendar(this.state.date)} className={'calendar'}/>
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 10}}>
                        <div style={{
                            width: 60,
                            padding: 10,
                            backgroundColor: '#53BAD5',
                            paddingLeft: 30,
                            paddingRight: 30,
                            borderRadius: 5,
                            display: 'flex',
                            justifyContent: 'center'
                        }} onClick={this.validateSelection}>
                            <text style={{color: 'white', cursor: 'pointer'}}>Save</text>
                        </div>
                    </div>
                </div>}
            {
                data?.map((item) => {
                    return <CommunityAssetComponent key={item.id} selectedItems={this.state.selectedRates} id={item.id}
                                                    url={item.signed_url || "image"}
                                                    onClick={(id: number, position: number) => {
                                                        this.onClick(id, position)
                                                    }}
                                                    unitId={item.creator as string}
                                                    createdAt={item.date_creation as number}
                                                    exhibitionDate={item.date_publish as number | undefined}
                    />
                })
            }
        </div>
    }

}
