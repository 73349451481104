import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {CommonFEModule} from "@modules/CommonFEModule";
import {BrowserHistoryModule, GenericSelect, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {ContactsModule} from "@modules/ContactsModule";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {ServerApiModule} from "@modules/ServerApiModule";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {config} from "../../../../config";
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'

enum Page {
    SelectUnit,
    Loading,
    Call
}

export const Playground_ZoomVideoCall = () => {
    const [units, setUnits] = useState<string[]>([])
    const [unitId, setUnitId] = useState("");
    const [contacts, setContacts] = useState<DB_Contact<any>[]>([])
    const [unitContactId, setUnitContactId] = useState("");
    const [contactId, setContactId] = useState("")
    const previewRef = useRef<HTMLDivElement | null>(null);
    const sessionRef = useRef<HTMLDivElement | null>(null);
    const [page, setPage] = useState(Page.SelectUnit)

    const connect = (signature: string, topic: string, name: string) => {
        try {
            uitoolkit.openPreview(previewRef.current!)
            uitoolkit.joinSession(sessionRef.current!, {
                videoSDKJWT: signature,
                sessionName: topic,
                userName: name,
                features: ['video', 'audio', 'share', 'chat', 'users', 'settings']
            })
            uitoolkit.closePreview(previewRef.current!)
            setPage(Page.Call)
            uitoolkit.onSessionClosed(goBackToSelectUnit)
        } catch (e) {
            console.error(e)
            goBackToSelectUnit()
        }
    }

    const goBackToSelectUnit = () => {
        setPage(Page.SelectUnit)
        setContactId("")
        const current = sessionRef.current;
        if (current)
            uitoolkit.closeSession(current)
    }

    useEffect(() => {
        CommonFEModule.getAllOrWait().then(units => {
            const _unitId = BrowserHistoryModule.getQueryParams()["unitId"];
            setUnits(units.map(unit => {
                if (_unitId === unit.unitId)
                    setUnit(unit.unitId)
                return unit.unitId;
            }))
        })
    }, [])
    const setUnit = (_unitId: string) => {
        setUnitId(_unitId)
        setContacts([])
        setContactId("")
        ContactsModule.getByUnit({unitId: _unitId, product: Elliq_ProductKey}, contactsByUnit => {
            const agentUserContact = contactsByUnit.find(c => c.contactData.unitId === _unitId);
            if (!agentUserContact) {
                ToastModule.toastError("No agent user contact found for unit " + _unitId)
                return
            }
            setUnitContactId(agentUserContact._id)
            setContacts(contactsByUnit.filter(c => c.contactType === "familyMember"))
        })
    }

    const setContact = (cId: string) => {
        setContactId(cId);
        (async () => {
            setPage(Page.Loading)
            const serverApiClient = await ServerApiModule.getClientWithAuthorization();
            const token = await XhrHttpModule.createRequest<any>(HttpMethod.GET, 'get token')
                .setRelativeUrl(`/v1/account/jwt`)
                .setUrlParams({unitId})
                .executeSync()

            await serverApiClient
                .post(`${config.ServerApiModule.serverApiUrl}/call/start/${unitId}`, {
                    unitId,
                    callerContactId: cId,
                    recipientContactId: unitContactId,
                }, {
                    headers: {
                        "Authorization": "Bearer " + token,
                    }
                })
                .then(async resp => {
                    const data = resp.data
                    if (data.appAccessToken) {
                        connect(data.appAccessToken, data.roomId, unitId + "_contact")
                        return;
                    }

                    if (data.voiceCallIdResponse) {
                        console.log("Call start responded with voice call id")
                        console.log(data.voiceCallIdResponse)
                    } else {
                        console.error("Call start failed")
                        console.error(data)
                    }
                    goBackToSelectUnit()
                }).catch(resError => {
                    console.error("Failed to delete cloud asset:", resError);
                    goBackToSelectUnit()
                })
        })().catch(console.error)

    }

    const getContactTypeInitials = (c: DB_Contact<any>) => {
        switch (c.contactType) {
            case "agentUser":
                return "AU";
            case "familyMember":
                return "FM";
            case "group":
                return "G";
            default:
                return c.contactType;
        }
    };

    const getPresentation = (c: DB_Contact<any>) => c.firstName + " " + (c.middleName ? " " + c.middleName : "") + c.lastName + " - " + getContactTypeInitials(c);

    return <div className={"ll_h_c space-around"} style={{minWidth: 500}}>
        {page === Page.SelectUnit ? <>
            <div style={{minWidth: 200}}>
                <GenericSelect<string>
                    options={units}
                    selectedOption={unitId}
                    iconClose={"D"}
                    iconOpen={"U"}
                    onChange={unitId => {
                        setUnit(unitId);
                        BrowserHistoryModule.addQueryParam("unitId", unitId)
                    }}
                    styles={{}}
                    presentation={u => u}
                />
            </div>
            {unitId && <div style={{minWidth: 200}}>
                <GenericSelect<DB_Contact<any>>
                    options={contacts}
                    selectedOption={contacts.find(c => c._id === contactId)}
                    iconClose={"D"}
                    iconOpen={"U"}
                    onChange={contact => setContact(contact._id)}
                    styles={{}}
                    presentation={getPresentation}
                />
            </div>}
        </> : <div className="App">
            {page == Page.Loading && <div ref={previewRef}/>}
            <div ref={sessionRef}></div>
        </div>
        }
    </div>
}
