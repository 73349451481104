import * as React from "react";
import {BugReportModule} from "@intuitionrobotics/bug-report/frontend";
import {StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Platform_Slack} from "@intuitionrobotics/bug-report";
import {
	__stringify,
	_setTimeout,
	Second
} from "@intuitionrobotics/ts-common";
import {
	Route_Playground
} from "../Routes";
import {Loader} from "../../widgets/Loader";
import {ErrorToast} from "../themes/toasts";

type State = {
	error?: string
	errorCount: number
}

type Props = {
	children: React.ReactNode
}

export class ErrorBoundary
	extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			errorCount: 0
		};
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		console.log(error,errorInfo);
		if('1'==='1')
			return;
		BugReportModule.sendBugReport(
			`Care Center Crashed for user ${StorageKey_UserEmail} at page: ${RoutingModule.getMyRouteKey()}`,
			"these logs were triggered by a UI failure\n" + __stringify(error.message), [Platform_Slack]);
		ErrorToast(
			"Something wrong happened, you are being redirected to the home page. If you experience more issues please refresh the page",
			3 * Second);
		_setTimeout(() => {
			// This still looks weird, probably there is a smarter way
			// Can avoid re-routing to main page if errorCount is too high...
			RoutingModule.goToRoute(Route_Playground);
			this.setState(state => ({error: undefined, errorCount: state.errorCount + 1}));
		}, 2 * Second);
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		BugReportModule.logWarning(error);
		return {error: __stringify(error)};
	}

	render() {
		if (this.state?.error)
			return <Loader overlay={true}/>;

		return this.props.children;
	}
}
