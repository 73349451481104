/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2018  Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import {
    BaseComponent,
    ConnectivityModule,
    OnConnectivityChange,
    RoutingModule,
    ThunderDispatcher,
    WrapperProps
} from "@intuitionrobotics/thunderstorm/frontend";
import {AccountModule, LoggedStatus, OnLoginStatusUpdated} from "@intuitionrobotics/user-account/frontend";
import {OnUnauthenticatedResponse} from "../index";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {Dialog_Connectivity} from "./dialogs/Dialog_Connectivity";
import {Loader} from "../widgets/Loader";
import {BadImplementationException} from "@intuitionrobotics/ts-common";
import {DataFetchingComponent} from "./DataFetchingComponent";
import {registerRoutes, Route_InHouseRoute, Route_IR_App, Route_IR_Website, Route_Video_Calling} from "./Routes";

export interface OnWindowResize {
    __onWindowResize: () => void
}

const dispatch_OnResize = new ThunderDispatcher<OnWindowResize, "__onWindowResize">("__onWindowResize");

type AppProps = WrapperProps & { isPWA: boolean };

export class App
    extends BaseComponent<AppProps>
    implements OnConnectivityChange {

    constructor(props: AppProps) {
        super(props);
    }

    componentDidMount(): void {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.onResize);
    }

    private onResize = () => {
        dispatch_OnResize.dispatchUI();
        dispatch_OnResize.dispatchModule();
    };

    __onConnectivityChange = () => {
        if (!ConnectivityModule.isConnected())
            return Dialog_Connectivity.show();
    };

    render() {
        registerRoutes();
        const routes = this.props.isPWA ? [Route_IR_Website,
            Route_IR_App,
            Route_Video_Calling] : [Route_InHouseRoute];
        return RoutingModule.getRoutesMap(routes);
    }
}

export class RenderHome
    extends BaseComponent
    implements OnUnauthenticatedResponse, OnLoginStatusUpdated {

    onLoginStatusUpdated = () => {
        this.forceUpdate();
    };

    onUnauthenticatedResponse = () => {
        AccountModule.logout();
        this.forceUpdate();
    };

    render() {
        const status = AccountModule.getLoggedStatus();
        switch (status) {
            case LoggedStatus.LOGGED_IN:
                return <DataFetchingComponent/>;

            case LoggedStatus.LOGGED_OUT:
                EnvironmentModule.redirectToLogin();
                return null;

            case LoggedStatus.VALIDATING:
                return <Loader overlay={true}/>;

            default:
                throw new BadImplementationException(`logged status can only be one of 'LOGGED_IN', 'LOGGED_OUT' or 'VALIDATING'`);
        }
    }
}

export class RouteToIRWebsite
    extends BaseComponent {

    componentDidMount(): void {
        window.location.replace('https://info.elliq.com/connect');
    }

    render() {
        return null;
    }
}

export class RouteToIRApp
    extends BaseComponent {
    private routeMap: { [k: string]: string } = {
        'new': "sms_to_contacts_15_06_22"
    };

    componentDidMount(): void {
        this.redirectTo();
    }

    private redirectTo() {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/windows phone/i.test(userAgent)) { //Keep this order
            this.logRedirect('windows-phone')
            window.location.href = 'https://info.elliq.com/connect';
            return;
        }

        if (/android/i.test(userAgent)) {
            this.logRedirect('android')
            window.location.href = 'https://play.google.com/store/apps/details?id=com.elliq';
            return;
        }

        if (/iPhone|iPad|iPod/i.test(userAgent)) {
            this.logRedirect('ios')
            window.location.href = "https://apps.apple.com/us/app/elliq-connect/id1412527777";
            return;
        }

        this.logRedirect('web', {userAgent})
        window.location.href = 'https://info.elliq.com/connect';
        return;
    }

    private logRedirect = (platform: string, extraProps?: { [key: string]: any }) => {
        let routeParam = window.location.href.split("/app")[1];
        if (routeParam && routeParam.startsWith("/"))
            routeParam = routeParam.slice(1);

        const campaign = this.convertRouteParam(routeParam)
        this.logInfo('redirect-to-store', {platform, routeParam, campaign, ...extraProps})
    };

    render() {
        return null;
    }

    private convertRouteParam = (routeParam: string) => this.routeMap[routeParam];
}
