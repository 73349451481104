import {Link} from "react-router-dom";
import {pathData} from "./query-util";
import React from "react";

export interface QueryNavigationProps {
    pathname: string;
}

export function QueryNavigation({pathname}: QueryNavigationProps) {
    return <nav className={"QueryNavigation"}>
        <ul>
            <li key={'/query'}>
                <Link to={'/query'} {...pathData('/query', pathname)}>New</Link>
            </li>
            <li key={'/query/list'}>
                <Link to={'/query/list'} {...pathData('/query/list', pathname)}>List</Link>
            </li>
        </ul>
    </nav>;
}