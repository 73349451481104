import * as React from "react";
import * as emotion from "emotion";
import {MessagesModule, OnGotSignedUrl, OnMessagesQueried} from "@modules/MessagesModule";
import {Loader} from "../../../../widgets/Loader";
import {ContactsModule, OnContactsUpdated} from "@modules/ContactsModule";
import {BaseComponent, GenericSelect, ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {CommonFEModule, OnUnitsLoaded} from "@modules/CommonFEModule";
import {UnitAndComment} from "@app/ir-q-app-common/types/units";
import {ContactCommunicationMethod, DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {ChatsModule, OnChatQueried} from "@modules/ChatsModule";
import {DB_Chat, DB_Message, MediaTypes, MessageMap} from "@app/ir-q-app-common/types/message";
import {__stringify, _keys, currentTimeMillies, sortArray} from "@intuitionrobotics/ts-common";
import {isMediaType} from "@app/app-shared/media-resource";
import {itemHover, itemPicked, NodeProps, Tree} from "../Tree";
import {StatusesModule} from "@modules/StatusesModule";
import {white} from "@styles/colors";
import {Dialog_ContactsInfo} from "../../contacts/Dialog_ContactsInfo";
import {getMediaTag} from "../../../utils/utils";

const breakingWord = emotion.css`
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 35vw;
`;

type State = {
	fromContact: string
	loading: boolean
	selectedUnit?: UnitAndComment
	selectedContact?: DB_Contact<keyof ContactCommunicationMethod>
}

export class Playground_MessageListWithPermission
	extends BaseComponent<{}, State>
	implements OnContactsUpdated, OnUnitsLoaded, OnChatQueried, OnMessagesQueried, OnGotSignedUrl {

	__onContactsUpdated = () => this.forceUpdate();
	__onUnitsLoaded = () => this.forceUpdate();
	__onChatQueried = () => this.forceUpdate();
	__onMessagesQueried = () => this.forceUpdate();
	__onGotSignedUrl = (path: string, signedUrl: string) => this.forceUpdate();

	constructor(props: {}) {
		super(props);
		this.state = {
			fromContact: "",
			loading: false
		};

		ChatsModule.query();
		MessagesModule.query();
		StatusesModule.query();

	}

	loading = () => {
		return this.state.loading ? <Loader/> : null;
	};

	render() {
		const {messageList} = this.getMessages();
		return <div className={"match_width match_height ll_h_c"} style={{height: "80vh", width: "97vw"}}>
			<div className={"match_width match_height ll_h_c"} style={{height: "80vh", width: "97vw"}}>
				{this.renderContactSelection()}
				<div className={"ll_v_c match_width"} style={{alignItems: "flex-start", maxHeight: "80vh", overflowY: "scroll"}}>
					{sortArray(messageList, message => message.timestamp).map(message => {
						const contact = ContactsModule.get(message.senderId);
						return <div style={{paddingBottom: 5, paddingTop: 5}} key={message._id}>
							<div style={{fontWeight: "bold"}}>
								{contact && <span>{contact.firstName} {contact.lastName} ({contact.contactType})</span>}
							</div>
							<div className={"ll_h_c"} style={{borderBottom: "1px solid"}}>
								<div style={{paddingRight: 10}}>
									{message.type}:
								</div>
								<div>
									{this.renderMessage(message)}
								</div>
							</div>
						</div>;
					})}
				</div>
				{this.loading()}
			</div>
			<div style={{overflow: "auto"}} className={"match_width match_height"}>
				{Playground_MessageListWithPermission.renderRawData(messageList)}
			</div>
		</div>;
	}

	private static renderRawData(messageList: DB_Message<any>[]) {
		if (!messageList.length)
			return null;

		const rawData = messageList.map(messageObj => {
			const status = StatusesModule.getLastStatusByMessageId(messageObj._id);
			// @ts-ignore
			messageObj.status = status;
			return <div key={messageObj._id} style={{margin: "20px", width: "100%"}}>
				<Tree
					id={`tree-${messageObj._id}`}
					renderer={MyTreeRenderer}
					root={messageObj}
					nodesState={Tree.recursivelyExpand(messageObj, (key: string, value: any, level: number) => {
						return level < 2;
					})}
				/>
			</div>;
		});

		return <>
			<h3>Raw Data</h3>
			{rawData}
		</>;
	}

	private renderContactSelection = (): React.ReactNode => {
		const contacts = this.state?.selectedUnit ? ContactsModule.getContactsByUnit(this.state?.selectedUnit) || [] : [];
		return <div className={"ll_v_c match_width"} style={{alignItems: "flex-start"}}>
			<div style={{display: "flex"}}>
				<div style={{marginBottom: 5, minWidth: 200}}>
					<GenericSelect<UnitAndComment>
						options={CommonFEModule.getUnits()}
						selectedOption={this.state?.selectedUnit}
						iconClose={"D"}
						iconOpen={"U"}
						onChange={(unit: UnitAndComment) => {
							ContactsModule.getByUnit(unit);
							const fromContact = ContactsModule.getUnitContact(unit);
							if (!fromContact)
								return;

							this.setState({selectedUnit: unit, fromContact: fromContact._id});
						}}
						styles={{minWidth: 200}}
						presentation={option => option.unitId}/>
				</div>
				{this.state?.selectedUnit && <button
					style={{marginLeft: 15}}
					onClick={() => Dialog_ContactsInfo.show({unit: this.state.selectedUnit})}>view contacts</button>}
			</div>
			<div style={{marginBottom: 5, minWidth: 200}}>
				<GenericSelect<DB_Contact<any>>
					options={contacts}
					selectedOption={this.state?.selectedContact}
					iconClose={"D"}
					iconOpen={"U"}
					onChange={(contact: DB_Contact<any>) => {
						// @ts-ignore
						ChatsModule.query({contactIds: {$ac: contact._id}});
						this.setState({selectedContact: contact});
					}}
					styles={{}}
					presentation={option => `${option.firstName} - ${option.lastName}`}/>
			</div>
		</div>;
	};

	private getMessages = (): { messageList: DB_Message<keyof MessageMap>[], chat?: DB_Chat } => {
		const selectedContact = this.state.selectedContact;
		const fromContact = this.state.fromContact;
		if (!selectedContact || !fromContact)
			return {messageList: []};

		const chats = ChatsModule.getChats();
		const relevantChats = chats.filter(chat => chat.contactIds.length === 2 &&
			chat.contactIds.includes(selectedContact._id) && chat.contactIds.includes(fromContact));

		if (relevantChats.length === 1) {
			const chosenChat = relevantChats[0];
			const items = MessagesModule.getMessages();
			const messageList = items.filter(message => message.chatId === chosenChat._id);
			return {chat: chosenChat, messageList};
		}

		if (relevantChats.length > 1)
			ToastModule.toastError("More than 1 chats found");
		if (!relevantChats.length)
			ToastModule.toastInfo("No chats found");
		return {chat: undefined, messageList: []};
	};

	private renderMessage = (message: DB_Message<keyof MessageMap>) => {
		if (message.type === "text")
			return message.data.text;

		if (isMediaType(message.type)) {
			const _message = message as DB_Message<MediaTypes>;
			if (_message.data.signedUrl && _message.data.ttl && _message.data.ttl > currentTimeMillies())
				return getMediaTag(message.type, _message.data.signedUrl);

			const path = _message.data.bucketPointer?.path;
			if (!path) {
				this.logError(`Something is very wrong. Message with id: ${_message._id} is of type ${message.type} but doesnt have a path?`);
				return;
			}

			const signedUrl = MessagesModule.getSignedUrl(path);
			if (signedUrl)
				return getMediaTag(message.type, signedUrl);

			return <button style={{borderRadius: 10, backgroundColor: white, padding: "2px 5px", margin: "5px 1px"}} onClick={() => {
				MessagesModule.fetchSignedUrl(_message._id);
			}}>Load resource</button>;
		}

		return "unknown message: " + __stringify(message.data);
	};
}

export const MyTreeRenderer = (props: NodeProps) => {

	function renderCollapse() {
		let toDisplay = "";
		if (typeof props.node !== "object")
			toDisplay = "";
		else if (Object.keys(props.node).length === 0)
			toDisplay = "";
		else if (_keys(props.node).filter((key) => props.filter((props.node as object), key)).length === 0)
			toDisplay = "";
		else if (props.expanded)
			toDisplay = "-";
		else
			toDisplay = "+";

		return <div className={`${itemHover} clickable`} id={props.path} onClick={props.expandToggler} style={{width: "15px"}}>{toDisplay}</div>;
	}

	let label;
	if (typeof props.node !== "object")
		label = ` : ${props.node}`;
	else if (Object.keys(props.node).length === 0)
		label = " : {}";
	else
		label = "";
	return (<div className="ll_h_c">
		{renderCollapse()}
		<div className={`${itemHover} ${itemPicked} ${breakingWord} clickable`} tabIndex={1} id={props.path}
			 onClick={props.onClick} onDoubleClick={props.onDoubleClick}>{props.name || "root"} {label}
		</div>
	</div>);
};
