import * as React from "react";
// import * as emotion from "emotion";
import {
	BaseComponent,
	Dialog_Builder,
	DialogModule,
	GenericSelect,
	SimpleScriptInjector,
	ToastModule,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	AddressDetails,
	ContactCommunicationMethod,
	GroupData,
	Relation,
	UpsertBody_ConnectionsData
} from "@app/ir-q-app-common/types/db-contact";
import {DB_Contact_View, FamilyMember_View} from "@app/ir-q-app-common/types/db-contact-view";
import {ContactType, Gender} from "@app/ir-q-app-common/types/contacts";
import {_values, auditBy, DB_Object, generateHex, PartialProperties} from "@intuitionrobotics/ts-common";
import {validateInternationalPhoneNumber} from "@app/app-shared/contact-login";
import {StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {ContactsModule, OnContactsUpdated} from "@modules/ContactsModule";
import Calendar from "react-calendar";
import {Loader} from "../../../widgets/Loader";
import moment from "moment";
import {Unit} from "@app/ir-q-app-common/types/units";
import Select from "react-select";

type Props = {
	contact?: DB_Contact_View<keyof ContactCommunicationMethod>
	ownerId: string
	unit?: Unit
	onSave?: () => any
	avoidCancelButton?: boolean
}

type State = {
	contact: Partial<DB_Contact_View<any>>
	showCalendar: boolean
	loading: boolean
}
const inputStyle = {border: "1px solid", padding: 5, margin: 10};

declare const google: any;

const flags = require("@res/flags.json");

export class Dialog_AddContact
	extends BaseComponent<Props, State>
	implements OnContactsUpdated {
	private googleLoaded: boolean = false;

	constructor(props: Props) {
		super(props);
		const contact = this.props.contact || {
			gender: Gender.Female,
			contactType: "familyMember",
			contactData: {},
			connections: [{_id: this.props.ownerId, relation: Relation.AGENT_USER}]
		};

		this.state = {
			contact,
			showCalendar: false,
			loading: false
		};
	}

	public static show(props: Props) {
		new Dialog_Builder(
			<Dialog_AddContact {...props} />)
			.setAllowIndirectClosing(true)
			.setStyle({width: "90%", height: "90%"})
			.show();
	}

	private autocomplete: any;
	private initAddress = {country: "", city: ""};

	private refAuto?: HTMLInputElement;

	__onContactsUpdated() {
		this.setState({loading: false});
	}

	private initAutocomplete = () => {
		if (!this.googleLoaded || !this.refAuto)
			return;

		const elementById = this.refAuto;
		this.autocomplete = new google.maps.places.Autocomplete((elementById), {types: ["geocode"]});
		this.autocomplete.setFields(["address_component"]);
		this.autocomplete.addListener("place_changed", this.fillInAddress);
	};

	private fillInAddress = () => {
		const address = this.autocomplete.getPlace();
		const addressState: AddressDetails = this.initAddress;
		if (address.address_components) {
			address.address_components.map((addProp: any) => {
				if (addProp.types.includes("street_number"))
					addressState.streetAddress = addProp.long_name;
				if (addProp.types.includes("route"))
					addressState.streetAddress = addressState.streetAddress ? addressState.streetAddress + ` ${addProp.long_name}` : addProp.long_name;
				if ((addProp.types.includes("locality")) || addProp.types.includes("postal_town"))
					addressState.city = addProp.long_name;
				if (addProp.types.includes("administrative_area_level_1"))
					addressState.region = addProp.long_name;
				if (addProp.types.includes("country"))
					addressState.country = addProp.long_name;
				if (addProp.types.includes("postal_code"))
					addressState.postalCode = addProp.long_name;
			});
			this.setState(oldState => {
				oldState.contact.address = addressState;
				return oldState;
			});
		}
	};

	private renderDefineContactData = () => {
		switch (this.state.contact.contactType) {
			case "familyMember":
				return this.renderFamilyMemberFields();
			case "agentUser":
				return this.renderAgentUserFields();
			default:
		}
	};

	render() {
		const contact = this.state.contact;
		return <>
			<SimpleScriptInjector
				src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDf4v9yF_ZmuO_F-zMhxdfdJVu2qlGk50M&libraries=places"
				onLoaded={() => {
					this.googleLoaded = true;
					this.initAutocomplete();
				}}/>
			<div className={"ll_v_c match_width match_height"} style={{padding: 10}}>
				<div style={{width: "100%", display: "contents"}}>
					<div style={{margin: 10}}>
						{`Contact type: ${contact.contactType}`}
					</div>
					<div className={"ll_h_c"}>
						{this.renderContactProp("firstName")}
						{this.renderContactProp("middleName")}
					</div>
					<div className={"ll_h_c"}>
						{this.renderContactProp("lastName")}
						{this.renderContactProp("SSML_Name")}
						{/*<iframe*/}
						{/*	src={`https://ir-q-studio-dev.firebaseapp.com/ttsPollyTest?text=${this.state.contact.SSML_Name}`}*/}
						{/*	title="button_specific"*/}
						{/*	style={{border: "0px", margin: 10, width: 29, height: 28}}*/}
						{/*/>*/}</div>
					<div style={{margin: 10, width: 178}}>
						<GenericSelect
							options={[
								Gender.Male,
								Gender.Female
							]}
							selectedOption={contact.gender}
							iconClose={""}
							iconOpen={""}
							onChange={(key: Gender) => {
								this.setState(oldState => {
									oldState.contact.gender = key;
									return oldState;
								});
							}}
							styles={{margin: 10, width: 180}}
							presentation={option => option}/>
					</div>
					{this.state.contact.contactType === "agentUser" && <div className={"ll_h_c"}>
						{this.renderZendeskInput()}
					</div>}
					<div className={"ll_h_c"}>
						{this.renderCalendar()}
					</div>
					<div className={"ll_h_c"}>
						{this.renderDefineContactData()}
					</div>
					<div className={"ll_h_c"}>
						{this.renderAddressFields()}
					</div>
					<div className={"ll_h_c"} style={{justifyContent: "center", margin: 20}}>
						{!this.props.avoidCancelButton && <button onClick={() => DialogModule.close()} style={{marginRight: 20}}>Cancel</button>}
						<button id={"saveContactButton"} name={"saveContactButton"} onClick={this.addContact}>{this.props.contact ? "Save Changes" : "Add Contact"}</button>
					</div>
				</div>
				{this.renderLoader()}
			</div>
		</>;
	}

	private renderCalendar = () => {
		const Date_1950 = -631152000000;
		const contact = this.state.contact;
		const momentBd = moment.utc(contact.birthday || Date_1950);
		const bd = contact.birthday ? new Date(contact.birthday) : new Date(Date_1950);
		/*Needs to change to a date picker*/
		return <div className={"ll_h_c"}>
			<div onClick={() => this.setState(oldState => ({showCalendar: !oldState.showCalendar}))}
			     style={{padding: 10}}>
				{bd.toDateString()} {momentBd.format("DD/MM/YYYY")}
			</div>
			{this.state.showCalendar && <Calendar
				onChange={(value)  => {
					const v = value as Date;
					this.setState(oldState => {
						const c = oldState.contact;
						c.birthday = moment.utc(new Date(v.getFullYear(), v.getMonth(), v.getDate(), 12)).valueOf();
						return {contact: c, showCalendar: false};
					});
				}}
				value={new Date(momentBd.format("YYYY-MM-DDT00:00:00Z"))}
			/>}
		</div>;
	};

	private renderContactProp = (contactProp: keyof DB_Contact_View<any>) => {
		const propAsString = String(contactProp);
		return <TS_Input
			id={`contact_${propAsString}`}
			type={"text"}
			value={this.state.contact[contactProp]}
			placeholder={propAsString}
			style={inputStyle}
			onChange={(value: string) => {
				this.setState(oldState => {
					oldState.contact[contactProp] = value;
					return oldState;
				});
			}}
		/>;
	};

	private renderZendeskInput = () => {
		return <TS_Input
			id={`zendesk`}
			type={"text"}
			value={this.state.contact.contactData?.zendeskTicketId}
			placeholder={"zendeskTicketId"}
			style={inputStyle}
			onChange={(value: string) => {
				this.setState(oldState => {
					oldState.contact.contactData.zendeskTicketId = value;
					return oldState;
				});
			}}
		/>;
	};

	private renderAddressFields = () => {
		const address = this.state.contact.address;
		return <fieldset
			style={{
				width: "84%",
				marginTop: 20,
				borderRadius: "0.5em",
				border: "0.1em solid #ccc",
				boxShadow: "0.08em 0.05em 0.08em #ccc"
			}}>
			<legend style={{fontSize: 14, color: "#777"}}>Address</legend>
			<input
				ref={r => {
					if (!r || this.refAuto)
						return;

					this.refAuto = r;
					this.forceUpdate();
				}}
				type={"search"}
				name={generateHex(4)}
				style={{...inputStyle, width: "96.7%"}}
				placeholder={"Please enter Address here, starting with street or street number"}
			/>
			{address?.country && <>
				<div className="ll_h_c match_width" style={{justifyContent: "space-between"}}>
					{this.renderAddressField("country")}
					{this.renderAddressField("region")}
					{this.renderAddressField("city")}
				</div>
				<div className="ll_h_c match_width" style={{justifyContent: "space-between"}}>
					{this.renderAddressField("streetAddress")}
					{this.renderAddressField("extendedAddress")}
					{this.renderAddressField("postalCode")}
				</div>
			</>}
		</fieldset>;
	};

	private renderAddressField = (addressKey: keyof AddressDetails) => {
		const keyAsString = String(addressKey);
		return <TS_Input
			id={`address_${keyAsString}`}
			type={"text"}
			value={this.state.contact.address?.[addressKey]}
			placeholder={keyAsString}
			style={inputStyle}
			onChange={(value: string) => {
				this.setState(state => {
					if (!state.contact.address)
						state.contact.address = {...this.initAddress};

					state.contact.address[addressKey] = value;
					return state;
				});
			}}
		/>;
	};

	preparePhoneNumber = (areaCode: string, phoneNumber: string) => {
		let fullPhoneNumber = phoneNumber.replace(areaCode, "");

		if (fullPhoneNumber.startsWith("0"))
			fullPhoneNumber = fullPhoneNumber.replace("0", "")

		fullPhoneNumber = `${areaCode}${fullPhoneNumber}`.trim();

		return fullPhoneNumber;
	}

	private addContact = () => {
		const contact = {...this.state.contact};

		if (!contact.firstName)
			return ToastModule.toastError(`Missing firstName`);

		if (!contact.lastName)
			return ToastModule.toastError(`Missing lastName`);


		const connectionsData: UpsertBody_ConnectionsData = {};
		switch (contact.contactType) {
			case "familyMember":
				const familyContactData = contact.contactData as FamilyMember_View;
				if (!familyContactData?.phoneNumber)
					return ToastModule.toastError(`Missing phone number`);

				if (!familyContactData?.areaCode)
					return ToastModule.toastError(`Missing area code`);

				if (!familyContactData.role)
					return ToastModule.toastError(`Missing role`);

				familyContactData.phoneNumber = this.preparePhoneNumber(familyContactData.areaCode, familyContactData.phoneNumber);

				if (!validateInternationalPhoneNumber.test(familyContactData.phoneNumber))
					return ToastModule.toastError(`please enter phone number in international format, for example: +972111111111`);

				if (!validateInternationalPhoneNumber.test(familyContactData.phoneNumber))
					return ToastModule.toastError(`please enter phone number in international format, for example: +972111111111`);

				connectionsData.role = familyContactData.role;

				if (!this.props.unit)
					return ToastModule.toastError(`unit is undefined - pls set it first`);

				connectionsData.agentUserData = {
					byUnit: this.props.unit,
					metadata: {
						firstName: contact.firstName,
						lastName: contact.lastName,
						SSML_Name: contact.SSML_Name || contact.firstName
					}
				};

				break;
			case "group":
				if (!(contact.contactData as GroupData).type)
					return ToastModule.toastError(`Missing type`);

				break;
			case "agentUser":
				const address = contact.address;
				if (!address || !address.country || !address.city)
					return ToastModule.toastError(`Missing address details - must include at least country and city`);

				if (!contact.contactData.phoneNumber || !contact.contactData.areaCode)
					break;

				contact.contactData.phoneNumber = this.preparePhoneNumber(contact.contactData.areaCode, contact.contactData.phoneNumber);

				if (!validateInternationalPhoneNumber.test(contact.contactData.phoneNumber))
					return ToastModule.toastError(`please enter phone number in international format, for example: +972111111111`);

				break;
			default:
				return ToastModule.toastError(`Case ${contact.contactType} not yet supported`);
		}

		const now = auditBy(StorageKey_UserEmail.get());
		if (!contact._created)
			contact._created = now;
		contact._updated = now;

		delete contact.contactData._id; // delete fe manipulation
		ContactsModule.createContact(contact as PartialProperties<DB_Contact_View<keyof ContactCommunicationMethod>, keyof DB_Object>, connectionsData,
		                             this.props.unit);
		// this.props.avoidCancelButton && window.postMessage({contact: this.state.contact}, "https://localhost")
		this.props.onSave && this.props.onSave();
		DialogModule.close();
	};

	private renderFamilyMemberFields = () => <div className={"ll_h_c"}>
		<div style={{margin: 10, width: 178}}>
			<GenericSelect<ContactType>
				options={_values(ContactType)}
				selectedOption={this.state.contact.contactData?.role}
				iconClose={""}
				iconOpen={""}
				onChange={(key: ContactType) => {
					this.setState(oldState => {
						oldState.contact.contactData.role = key;
						return oldState;
					});
				}}
				styles={""}
				placeholder={"Role"}
				presentation={option => option}/>
		</div>
		{this.renderAreaCodeField<"familyMember">("areaCode")}
		{this.renderDataField<"familyMember">("phoneNumber", value => value.replace(/-/g, ""))}
		{this.renderDataField<"familyMember">("email")}
	</div>;

	private renderAreaCodeField = <K extends keyof ContactCommunicationMethod, T extends keyof ContactCommunicationMethod[K] = keyof ContactCommunicationMethod[K]>(
		dataKey: T
	) => {

		const renderLabel = (f: { flag: string, dialCode: string }) => {
			return <div className={"ll_h_c"}>
				<p>{f.dialCode}</p>
				<img style={{width: 40, height: 40, marginLeft: 10}} src={`data:image/svg+xml;utf8,${encodeURIComponent(f.flag)}`}/>
			</div>;
		}

		return <div style={{margin: 10, width: 200}}>
			<Select
				options={_values(flags).sort((f1, f2) => f1.dialCode - f2.dialCode).map(f => ({label: renderLabel(f), value: f.dialCode}))}
				onChange={item => {
					this.setState(oldState => {
						oldState.contact.contactData[dataKey] = item?.value;
						oldState.contact.contactData["phoneNumber"] = oldState.contact.contactData["phoneNumber"]?.replace(item?.value, "");
						return oldState;
					});
				}}
				isSearchable={true}
				placeholder={this.state.contact.contactData[dataKey]}
			/>
		</div>
	};

	private renderDataField = <K extends keyof ContactCommunicationMethod, T extends keyof ContactCommunicationMethod[K] = keyof ContactCommunicationMethod[K]>(
		dataKey: T,
		valueProcessor?: (v: string) => ContactCommunicationMethod[K][T]
	) => {
		const keyAsString = String(dataKey);
		const areaCode = this.state.contact.contactData.areaCode;
		return <TS_Input
			id={`contact_${keyAsString}`}
			type={"text"}
			value={areaCode && this.state.contact.contactData?.[dataKey] ? this.state.contact.contactData?.[dataKey].replace(areaCode, "") : this.state.contact.contactData?.[dataKey]}
			placeholder={keyAsString}
			style={inputStyle}
			onChange={(value: string) => {
				this.setState(oldState => {
					oldState.contact.contactData[dataKey] = valueProcessor ? valueProcessor(value) : value;
					return oldState;
				});
			}}
		/>;
	};

	private renderAgentUserFields = () => <div className={"ll_h_c"}>
		{this.renderDataField<"agentUser">("recognitionId")}
		{this.renderAreaCodeField<"familyMember">("areaCode")}
		{this.renderDataField<"familyMember">("phoneNumber", value => value.replace(/-/g, ""))}
	</div>;

	private renderLoader = () => {
		if (!this.state.loading)
			return;

		return <div className={"match_height match_width"} style={{position: "absolute"}}>
			<Loader/>
		</div>;
	};
}
