import React, {useRef, useState} from "react";
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {MemoryQuery, MemoryQueryList} from "./query-model";
import LoadingSpinner from "./LoadingSpinner";
import {config} from "../../../config";

const INITIAL_STATE = {
    total: 0,
    memoryQueries: []
};

// @ts-ignore
let intId = null;

export interface ListQueryProps {
    onClick: (query: MemoryQuery | null) => void,
    onFetchStateChange: (state: boolean, e?: Error) => void
}

export function ListQuery({onClick, onFetchStateChange}: ListQueryProps) {
    // State declarations
    const [filter, setFilter] = useState<string>('');
    const [start, setStart] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [queryList, setQueryList] = useState<MemoryQueryList>(INITIAL_STATE);
    const [selectedQuery, setSelectedQuery] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(false);

    const resultsSectionRef = useRef<HTMLDivElement>(null);

    // Retrieve the query history list from the server
    async function fetchHistory(s: number, p: number, f: string | undefined, fireStateChange = false) {
        try {
            if (fireStateChange)
                onFetchStateChange(true)

            const token: string = await XhrHttpModule.createRequest<any>(HttpMethod.GET, 'get token')
                .setRelativeUrl(`/v1/account/jwt?exp=${Math.floor((Date.now() + 500000) / 1000)}`)
                .executeSync();

            const queryParams = new URLSearchParams();
            queryParams.append('start', s.toString());
            queryParams.append('pageSize', p.toString());
            queryParams.append('filter', f || '');

            const headers = new Headers();
            headers.append('Authorization', `Bearer ${token}`);
            headers.append('Content-Type', 'application/json');

            const res = await fetch(`${config.ServerApiModule.serverApiUrl}/conversationAnalyzer/getMemories?${queryParams.toString()}`, {
                method: 'GET',
                headers: headers
            });

            if (res.status !== 200) {
                onFetchStateChange(false, new Error(`Failed to query memories: ${res.statusText}`));
                return INITIAL_STATE;
            }

            if (fireStateChange)
                onFetchStateChange(false)

            return await res.json();

        } catch (e) {
            if (fireStateChange)
                onFetchStateChange(false, e as Error)
            return INITIAL_STATE;
        } finally {
            setLoading(false);
        }
    }

    // Handler for filter changes, this triggers population of the table
    const updateFilter = async (value: string) => {
        setLoading(true);
        setFilter(value);
        // @ts-ignore
        clearInterval(intId);
        intId = setTimeout(async () => {
            const newQueryList = await fetchHistory(start, pageSize, value);
            setQueryList(newQueryList);
        }, 1000);
    }

    React.useEffect(() => {
        // Populate on init
        fetchHistory(start, pageSize, filter).then(setQueryList);
    }, [start, pageSize, filter]);

    // Handler for row click, will populate the results section
    const handleRowClick = (query: MemoryQuery, index: number) => {
        onClick(query);
        setSelectedQuery(index);

        if (resultsSectionRef.current) {
            resultsSectionRef.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    const rows = queryList.memoryQueries.map((q, index) => {
        const query = q as MemoryQuery;
        return (
            <ul className={'query-list-item'}
                key={index}
                onClick={() => handleRowClick(query, index)}
                data-selected={selectedQuery === index ? 'selected' : ''}
            >
                <li className={'id'}>{query.id}</li>
                <li className={'timestamp'}>{new Date(query.timestamp).toLocaleString()}</li>
                <li className={'agentId'}>{query.agentId}</li>
                <li className={'question'}>{query.question}</li>
                <li className={'k'}>{query.k}</li>
                <li className={'threshold'}>{query.threshold}</li>
                <li className={'user'}>{query.user.replace(/"/g, '')}</li>
            </ul>
        );
    });

    if (rows.length) {
        rows.unshift(
            <ul className={'query-list-item'} key={'header'}>
                <li className={'id'}>ID</li>
                <li className={'timestamp'}>Timestamp</li>
                <li className={'agentId'}>Agent Id</li>
                <li className={'question'}>Question</li>
                <li className={'k'}>K</li>
                <li className={'threshold'}>Threshold</li>
                <li className={'user'}>User</li>
            </ul>
        );
    }

    const showPagination = queryList.total > pageSize;

    return (
        <div className={"ListQuery animate-fade-in"}>
            <h3>Query history</h3>
            <div className={"query-filter"}>
                <label>Filter</label>
                <input type="text" value={filter} onChange={e => updateFilter(e.target.value)}/>
                {loading && <LoadingSpinner className={'results-loading'} size={20}/>}
            </div>
            <div className={"query-list"}>
                {rows}
            </div>
            {showPagination &&
                <div className={"query-pagination"}>
                    <label>Page size (total results {queryList.total})</label>
                    <select value={pageSize} style={{width: '50px'}} onChange={e => setPageSize(parseInt(e.target.value))}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                    &nbsp;&nbsp;
                    <button disabled={start === 0} onClick={() => setStart(start - pageSize)}>Previous</button>
                    &nbsp;&nbsp;
                    <button disabled={start + pageSize >= queryList.total} onClick={() => setStart(start + pageSize)}>Next</button>
                </div>
            }
        </div>
    );
}
