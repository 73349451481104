export const COLORS = {
	veryLightPink: "#d8d8d8",
	blueGrey: "#8181a8",
	transparent: "#00ffff",
	gold: "#B5986E",
	amber: "#A15F3E",
	mint: "#E3EFE2",
	grays_1: `rgba(237, 242, 246, 1)`,
	grays_05: `rgba(237, 242, 246, 0.5)`,
	grays_2: `rgba(203, 211, 221, 1)`,
	grays_4: `rgba(86, 98, 112, 1)`,
	grays_gray_blue: `rgba(131, 146, 166, 1)`,
	grays_dark: `rgba(0, 22, 54, 1)`,
	red: `rgb(255, 47, 47)`,
	secondary_red: "#c91e29",
	secondary_red_10: `rgba(201, 30, 41, 0.2)`,
	semantic_blue: `rgba(0, 147, 255, 1)`,
	info_dark_orange: `rgba(223, 152, 84, 1)`,
	primary_blue_10: `rgba(45, 188, 207, 0.1)`,
	systemGreen: "#0ac5b7",
	very_light_blue: "#e4eff6",
	neutral_text_dark: "#2a3138",
	neutral_text_dark_2: "#566270",
	primary_dark_blue: "#026691",
	primary_dark_blue_hover: "#034f70",
	neutral_gray_1: "#a6aab2",
	neutral_gray_2: "#ccced0",
	neutral_gray_4: "#cbd3dd",
	primary_blue: "#53bad5",
	primary_light_blue: "#cae3ee",
	primary_blue_hover: "#349db9",
	box_shadow: `rgba(20, 46, 110, 0.1)`,
	color_gray_medium: '#ececee',
	neutral_light: '#fafbfd',
	neutral_graybluish: "#f6f6f9",
	bluish: "#2c80a3",
	sap_green: "#6c8d13",
	purple_red: "#9d004c",
	blue_sky: "#1e81c9",
	orange: "#de9519",
	steelGray: "#7b8084",
	secondary_green: "#9db853",
	macaroni_and_cheese: "#ee9c2c",

	// Specified in Figma.
	white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
	salmon: (opacity = 1) => `rgba(254, 145, 118, ${opacity})`,
	brand_blue: (opacity = 1) => `rgba(22, 52, 110, ${opacity})`,
	midnight_blue: (opacity = 1) => `rgba(10, 34, 59, ${opacity})`,
	brand_green: (opacity = 1) => `rgba(65, 164, 152, ${opacity})`,
	classic_red: (opacity = 1) => `rgba(252, 72, 37, ${opacity})`,
	error_red: (opacity = 1) => `rgba(148, 29, 41, ${opacity})`,
	dark_salmon: (opacity = 1) => `rgba(158, 89, 72, ${opacity})`,
	grey_bg: (opacity = 1) => `rgba(41, 41, 50, ${opacity})`
	// No dimming:
	// grey_bg:  (opacity = 1) => `rgba(56, 55, 68, ${opacity})`,
};