import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {QueryResponse} from "./query-model";
import {ListQuery} from "./ListQuery";
import {CreateQuery} from "./CreateQuery";
import {Results} from "./Results";
import {QueryNavigation} from "./QueryNavigation";
import './styles.css';
import LoadingSpinner from "./LoadingSpinner";

export default function Query() {
    // useLocation hook to get the current path
    const {pathname} = useLocation();

    // State for managing loading, result, and error
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<QueryResponse | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const location = useLocation();

    useEffect(() => {
        setResult(null);
        setError(null);
        setLoading(false)
    }, [location]);

    // Function to determine which component to render based on the path
    const content = (() => {
        switch (pathname) {
            case '/query':
                // Render CreateQuery component for '/query' path
                return <CreateQuery
                    onQueryStarted={() => {
                        setError(null)
                        setLoading(true);
                    }} // Start loading
                    onQueryFinished={r => {
                        setLoading(false); // Stop loading
                        setResult(r); // Set the query result
                    }}
                    onQueryError={e => {
                        setLoading(false); // Stop loading on error
                        setError(e) // Set the error state
                    }}/>;
            case '/query/list':
                // Render ListQuery component for '/query/list' path
                return <ListQuery
                    onFetchStateChange={(s, e) => {
                        setLoading(s); // Update loading state based on fetch state
                        if (e) {
                            setError(e); // Set error if exists
                            console.error(e) // Log error to console
                        }
                    }}
                    onClick={mq => {
                        if (!mq) {
                            setResult(null); // Clear result if no query is selected
                            return;
                        }
                        // Parse and set the query result
                        setResult(JSON.parse(mq.result as string) as QueryResponse);
                    }}/>;
            default:
                // Default case to handle unknown paths
                return <div>Not found</div>;
        }
    })();

    return (
        <div className={"Query"}>
            <QueryNavigation pathname={pathname}/> {/* Render the navigation bar */}
            <div className={`query-content ${result === null ? 'full-width' : ''}`}>
                {/* Content section based on the current route */}
                <section>{content}</section>
                {/* Conditionally render the Results component if there is a result or an error */}
                {(result || error) && <section><Results result={result} error={error}/></section>}
            </div>
            {/* Render LoadingSpinner with overlay if loading state is true */}
            {loading && (<div className="loading-overlay"><LoadingSpinner/></div>)}
        </div>
    );
}
