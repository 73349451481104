import React, {FormEvent, useState} from "react";
import {Unit} from "@app/ir-q-app-common/types/units";
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {QueryResponse} from "./query-model";
import {SelectUnit} from "./SelectedUnit";
import {config} from "../../../config";

// Define props for the CreateQuery component
interface CreateQueryProps {
    onQueryStarted: () => void;
    onQueryFinished: (result: QueryResponse) => void;
    onQueryError: (error: Error | null) => void;
}

export function CreateQuery({onQueryStarted, onQueryFinished, onQueryError}: CreateQueryProps) {
    // State declarations
    const [_, setSelectedUnit] = useState<Unit>();  // '_' is used when the variable is not needed
    const [agentId, setAgentId] = useState<string>('');
    const [question, setQuestion] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    // Handlers for form inputs
    const onAgentIdChange = (unit: Unit) => setAgentId(unit.unitId);
    const onQuestionChange = (e: FormEvent<HTMLInputElement>) => setQuestion(e.currentTarget.value);

    // Form submission handler
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        onQueryStarted();
        try {
            const token: string = await XhrHttpModule.createRequest<any>(HttpMethod.GET, 'get token')
                .setRelativeUrl(`/v1/account/jwt?exp=${Math.floor((Date.now() + 500000) / 1000)}`)
                .executeSync();

            const queryParams = new URLSearchParams();
            queryParams.append('agentId', agentId);
            queryParams.append('question', question);
            queryParams.append('user', localStorage.getItem('storage-userEmail') || 'unknown');

            const headers = new Headers();
            headers.append('Authorization', `Bearer ${token}`);
            headers.append('Content-Type', 'application/json');


            const res = await fetch(`${config.ServerApiModule.serverApiUrl}/conversationAnalyzer/query?${queryParams}`, {
                method: 'GET',
                headers
            })

            if (res.status !== 200) {
                onQueryError(new Error(`Failed to query memories: ${res.statusText}`));
                return;
            }

            const json = await res.json();
            onQueryFinished(json as QueryResponse);
        } catch (e) {
            onQueryError(e as Error);
        } finally {
            setLoading(false);
        }
    };

    // Determine if the submit button should be disabled
    const disabled = !agentId || !question || loading;

    return (
        <form className={'query-form animate-fade-in'} onSubmit={onSubmit}>
            <h3>Query properties</h3>

            {/* Agent ID selection */}
            <div className={'query-row'}>
                <label htmlFor="agent-id">Agent id</label>
                <div className={'row-inputs'}>
                    <div className={'unit-selector'}>
                        <SelectUnit setSelectedUnit={setSelectedUnit} onChange={onAgentIdChange} disabled={loading}/>
                    </div>
                </div>
            </div>

            {/* Question input */}
            <div className={'query-row'}>
                <label htmlFor="query-question">Question</label>
                <div className={'row-inputs'}>
                    <input type={'text'} id={'query-question'} name={'query-question'} disabled={loading} value={question} onChange={onQuestionChange}/>
                </div>
            </div>


            {/* Submit button */}
            {!disabled && <div className={'query-row'}>
                <button type={'submit'} disabled={disabled}>Submit</button>
            </div>}
        </form>
    );
}
