import * as React from "react";
import {ReactNode} from "react";
import {DialogModule,} from "@intuitionrobotics/thunderstorm/frontend";

type Props = {
	title: string
	subheader?: string | ReactNode
}

export class DialogTitle
	extends React.Component<Props> {

	render() {
		return <div>
			<div className={'ll_h_c'}
			     style={{paddingTop: 21, paddingRight: 25, paddingLeft: 25, paddingBottom: this.props.subheader ? 3 : 12}}>
				<div>{this.props.title}</div>
				<div style={{flex: 1}}/>
				<div className='clickable' style={{position: "relative", top: -10, right: -10}} onClick={() => DialogModule.close()}>X</div>
			</div>
			{this.props.subheader &&
			<div style={{fontSize: "12px", paddingLeft: 25, paddingRight: 25, display: "block"}}>{this.props.subheader}</div>
			}
		</div>;
	}
}
