import * as React from "react";
import {
	ErrorHandler_Log,
	ThunderInstance
} from "./index";
import {App} from "./app/App";
import {
	ErrorHandler_Dispatch,
	WrapperProps,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {ContactsModule} from "@modules/ContactsModule";
import {UnitCloudAssetsModule} from "@modules/UnitCloudAssetsModule";
import {Frontend_ModulePack_Uploader} from "@intuitionrobotics/file-upload/frontend";

ThunderInstance
	.setMainApp((props: WrapperProps) => <App {...props} isPWA={false}/>)
	.addModules(...Frontend_ModulePack_Uploader)
	.addModules(ContactsModule, UnitCloudAssetsModule)
	.build(() => {
		XhrHttpModule.logInfo(`Starting debug app Env: ${EnvironmentModule.getEnvName()}`);
		XhrHttpModule.setErrorHandlers([ErrorHandler_Dispatch,
		                                ErrorHandler_Log]);
	});
