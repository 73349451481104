import * as React from "react";
import {chipGroupCss, chipGroupNewEntry} from "./css/chipsCss";

type State = {
    newEntry: string;
}

type Props = {
    onNewEntry?: (newEntry: string) => void;
    editable?: boolean;
    children: React.ReactNode
}

const icon__add_blue = require('@res/images/icon_add_blue.svg');

export class ChipGroup
    extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            newEntry: ''
        }
    }
    render() {
        return <div className={chipGroupCss}>
            {this.props.children}
                {
                    !!this.props.editable &&
                    <div className={chipGroupNewEntry}>
                        <input className={chipGroupNewEntry} type={'text'} value={this.state.newEntry} onChange={
                            (e) => {
                                this.setState({newEntry: e.currentTarget.value})
                            }} placeholder={'Add new entry'}
                        />
                        <img alt={'close'} onClick={() => {
                            if (this.props.onNewEntry)
                                this.props.onNewEntry(this.state.newEntry);
                            this.setState({newEntry: ''});
                        }} src={icon__add_blue}/>
                    </div>
                }
        </div>;
    }
}
