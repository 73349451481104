import {Module, Second} from "@intuitionrobotics/ts-common";
import {FirebaseModule} from "@intuitionrobotics/firebase/frontend";
import {ThunderDispatcher, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm/shared/types";
import {collection, onSnapshot, query, Unsubscribe} from "firebase/firestore";
import {DB_Object, FirebaseConfig} from "@intuitionrobotics/firebase/shared/types";
import {MessagingGetFirebaseToken} from "@app/app-shared";

export interface OnCollectionUpdated {
    __onCollectionUpdated: (collectionName: string) => void;
}

export const dispatch_onCollectionUpdated = new ThunderDispatcher<OnCollectionUpdated, "__onCollectionUpdated">("__onCollectionUpdated");

export class FirebaseListenerModule_Class
    extends Module {

    private subscription?: Unsubscribe;
    private data: DB_Object[] = [];

    private readonly collectionName;

    constructor(collectionName: string, moduleName: string) {
        super(moduleName);
        this.collectionName = collectionName;
    }

    getCollectionName() {
        return this.collectionName;
    }

    init() {
        super.init()
    }

    public stopListening() {
        if (this.subscription)
            this.subscription()
    }

    public getData() {
        return this.data;
    }

    public listenImpl = () => {

        XhrHttpModule
            .createRequest<MessagingGetFirebaseToken>(HttpMethod.GET, "get-firebase-token")
            .setRelativeUrl(`/v1/firebase-token/get`)
            .setTimeout(60 * Second)
            .setLabel(`Listening to collection`)
            .setOnError(`Failed to listen to collection`)
            .execute(async (response: { token: string }) => {

                const token = response.token;

                // @ts-ignore
                const options: FirebaseConfig = FirebaseModule.config.local;
                const app = await FirebaseModule.createSession(options, token)
                const firestore = app.getFirestore()

                const q = query(collection(firestore, this.collectionName));
                this.subscription = onSnapshot(q, (snapshot) => {

                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as DB_Object;
                        if (change.type === "added") {
                            this.data.push(data);
                        }
                        if (change.type === "modified") {
                            this.data = this.data.filter(d => d._id !== data._id)
                            this.data.push(data)
                        }
                        if (change.type === "removed") {
                            this.data = this.data.filter(d => d._id !== data._id)
                        }

                    });
                    dispatch_onCollectionUpdated.dispatchUI(this.collectionName)
                });

            });
    };
}
