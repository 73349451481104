import {BaseComponent, GenericSelect} from "@intuitionrobotics/thunderstorm/frontend";
import {CommonFEModule, OnUnitsLoaded} from "@modules/CommonFEModule";
import {Unit} from "@app/ir-q-app-common/types/units";
import React from "react";

export interface SelectedUnitProps {
    setSelectedUnit: (unit: Unit) => void;
    onChange?: (unit: Unit) => void;
    disabled?: boolean;
}

export interface SelectedUnitState {
    selectedUnit?: Unit;
}

export class SelectUnit extends BaseComponent<SelectedUnitProps, SelectedUnitState> implements OnUnitsLoaded {
    selectedUnit?: Unit;


    constructor(props: SelectedUnitProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <GenericSelect<Unit>
            isDisabled={this.props.disabled}
            options={CommonFEModule.getUnits()}
            selectedOption={this.state.selectedUnit}
            iconClose={"D"}
            iconOpen={"U"}
            onChange={unit => {
                this.setState({selectedUnit: unit})
                !this.props.onChange || this.props.onChange(unit)

            }}
            styles={{}}
            presentation={option => option.unitId}/>
    }

    __onUnitsLoaded(): void {
        this.forceUpdate();
    }

}