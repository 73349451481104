import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import React from "react";
import {buttonStyle} from "@styles/buttonStyle";
import {ImageConfigModule} from "@modules/ImageConfigModule";
import {CloudAssetConfigKeysMapping, SQL_ImageConfig} from "@app/ir-q-app-common/types/cloud-asset";
import {DefaultCloudAssetConfig} from "@app/app-shared/image-config";

type Props = {
    dataEnvs: string[]
}

type State = {
    isEditing: boolean;
    showLoader: boolean;
    editingConfig: Map<string, SQL_ImageConfig>;
    editedUserGroups: Set<string>;
};


class ImageConfigTable
    extends BaseComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isEditing: false,
            showLoader: false,
            editingConfig: this.createAllEnvsEditingConfig(),
            editedUserGroups: new Set()
        }
    }

    initState = () => {
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isEditing = false;
            currState.editingConfig = this.createAllEnvsEditingConfig();
            currState.editedUserGroups = new Set();
            return currState;
        })
    }

    createAllEnvsEditingConfig(): Map<string, SQL_ImageConfig> {
        const imageConfig = ImageConfigModule.getImageConfig();
        return this.props.dataEnvs.reduce((map: Map<string, SQL_ImageConfig>, label) => {
            const imageConfigItem = imageConfig.find(({user_group}) => user_group === label);
            return map.set(label, {...DefaultCloudAssetConfig, ...imageConfigItem, user_group: label})
        }, new Map());
    }

    cancelChanges = () => {
        this.initState();
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        const editedConfigs: SQL_ImageConfig[] = Array.from(this.state.editingConfig.entries())
            .filter(([key]) => this.state.editedUserGroups.has(key))
            .map(([, value]) => value);
        ImageConfigModule.updateImageConfig(editedConfigs).finally(() => this.initState());
    }

    render() {
        const rowsWithErrors = Array.from(this.state.editingConfig.values()).map((row) => {
            let rowSum = 0;
            for (const rowKey of Object.keys(row)) {
                if (rowKey === "id")
                    continue

                const numericValue = row[rowKey as keyof SQL_ImageConfig] as number;
                rowSum += (typeof numericValue === 'number' && !Number.isNaN(numericValue) ? numericValue : 0)
            }

            const numericRowSum = rowSum as number;
            let errorMessage = '';
            if (numericRowSum < 100) {
                errorMessage = 'Group percentage is lower than 100%';
            } else if (numericRowSum > 100) {
                errorMessage = 'Group percentage is higher than 100%';
            }

            return {
                row,
                errorMessage,
            };
        });

        return (
            <div>
                <div className={"image-config-table-container"}>
                    <table className="table table-hover">
                        <tbody>
                        <tr>
                            <th></th>
                            {Object.keys(CloudAssetConfigKeysMapping).map(key => (
                                <th key={key}>{CloudAssetConfigKeysMapping[key as keyof typeof CloudAssetConfigKeysMapping]}</th>
                            ))}
                        </tr>

                        {Array.from(this.state.editingConfig.values()).map(row => {
                            const errorMessage = rowsWithErrors.find((rowData) => rowData.row.user_group === row.user_group)?.errorMessage || '';
                            return this.renderTableRow(row.user_group, errorMessage);
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="cloud-asset-actions">
                    {!this.state.isEditing ?
                        <div></div>
                        :
                        <>
                            <div
                                style={buttonStyle('green', rowsWithErrors.some((row) => row.errorMessage))}
                                onClick={(event) => {
                                    const hasErrors = rowsWithErrors.some((row) => row.errorMessage);
                                    if (hasErrors) {
                                        return;
                                    }

                                    this.handleSubmit(event);
                                }}
                            >
                                Save
                            </div>
                            <div style={buttonStyle("red")} onClick={() => {
                                this.cancelChanges();
                            }}>
                                Cancel
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }

    private renderTableRow(userGroup: string, errorMessage?: string) {
        const columnAccessors = Object.keys(CloudAssetConfigKeysMapping) as (keyof typeof CloudAssetConfigKeysMapping)[];
        let row = this.state.editingConfig.get(userGroup) as SQL_ImageConfig;
        return (
            <tr>
                <td>
                    {userGroup}
                </td>
                {
                    columnAccessors.map(key => {
                        return (
                            <td key={key}>
                                <input
                                    id={key}
                                    type={"number"}
                                    value={row[key as keyof typeof DefaultCloudAssetConfig]}
                                    onChange={(event) => {
                                        const value = event.target.valueAsNumber;
                                        if (value >= 0 || event.target.value === "") {
                                            row = {...row, [key]: value}
                                            this.setState((prevState) => {
                                                const currState = {...prevState};
                                                currState.isEditing = true;
                                                currState.editingConfig.set(userGroup, row);
                                                currState.editedUserGroups.add(userGroup);
                                                return currState;
                                            })

                                        }
                                    }}
                                    style={{
                                        border: "1px solid gray",
                                        display: "block",
                                        marginBottom: "5px"
                                    }}
                                />
                            </td>
                        );
                    })
                }
                <td>
                    {errorMessage && <span style={{color: 'red'}}>{errorMessage}</span>}
                </td>
            </tr>
        );

    }
}

export default ImageConfigTable;
