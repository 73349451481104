import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {
	Api_CreateContact,
	Api_DeleteContact,
	Api_GetUnitContacts,
	Api_HardDeleteContact,
	Api_SetEmergencyContact,
	Body_SetEmergencyContact,
	DB_Contact,
	DeleteContact_Body,
	QueryContacts,
	UpsertBody_ConnectionsData
} from "@app/ir-q-app-common/types/db-contact";
import {Unit} from "@app/ir-q-app-common/types/units";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {DB_Object, Exception, PartialProperties} from "@intuitionrobotics/ts-common";
import {BaseHttpRequest} from "@intuitionrobotics/thunderstorm/shared/BaseHttpRequest";
import {ErrorResponse} from "@intuitionrobotics/thunderstorm/shared/types";
import {ApiBinder_DBCreate, DefaultApiDefs} from "@intuitionrobotics/db-api-generator";
import {DB_Contact_View} from "@app/ir-q-app-common/types/db-contact-view";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";

export interface OnContactsUpdated {
	__onContactsUpdated: () => void;
}

export interface OnContactsUpserted {
	__onContactsUpserted: () => void;
}

export const dispatch_onContactsUpdated = new ThunderDispatcher<OnContactsUpdated, "__onContactsUpdated">("__onContactsUpdated");
export const dispatch_onContactsUpserted = new ThunderDispatcher<OnContactsUpserted, "__onContactsUpserted">("__onContactsUpserted");

const CREATE_CONTACT_KEY = `request-api--contacts-create`;

export class ContactsModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_Contact<any>> {

	private isUnitIdWithLandline: { [unitId: string]: boolean } = {};

	private queryConsumers: (() => void)[] = [];

	protected init() {
		super.init();
		this.setDefaultDispatcher(dispatch_onContactsUpdated);
		this.query({contactType: "agentUser"});
	}

	async getAllOrWait() {
		if (this.getItems().length)
			return this.getItems();

		return new Promise<DB_Contact<any>[]>(resolve => this.queryConsumers.push(() => resolve(this.getItems())));
	}

	create(toCreate: PartialProperties<DB_Contact<any>, "_id">): BaseHttpRequest<ApiBinder_DBCreate<DB_Contact<any>>> {
		throw new Exception('Use createContact function');
	}

	createContact(toCreate: PartialProperties<DB_Contact<any>, "_id">, connectionsData: UpsertBody_ConnectionsData, unit?: Unit) {
		XhrHttpModule
			.createRequest<Api_CreateContact>(HttpMethod.POST, CREATE_CONTACT_KEY)
			.setRelativeUrl(`/v2/contacts/create`)
			.setJsonBody({contact: toCreate, connectionsData})
			.setOnError(this.onError)
			.execute((response: DB_Contact<any>) => {
				const byUnit = connectionsData.agentUserData?.byUnit || unit
				if (byUnit) {
					return this.getByUnit({...byUnit});
				}
				dispatch_onContactsUpdated.dispatchUI();
				dispatch_onContactsUpserted.dispatchUI();
			});
	}

	getContactsByUnit = (unit: Unit): void | DB_Contact_View<any>[] => {
		const items = this.getItems()
		const contact = this.getUnitContactImpl(unit);
		if (!contact)
			return this.logInfo(`No contact for unit ${unit.unitId}`);

		const _items = items.filter(c => {
			if (this.isUnitIdWithLandline[unit.unitId] && c.contactType === "landline")
				return true;

			return contact.connections.find(_c => _c._id === c._id)
		});
		_items.unshift(contact);
		return _items;
	};

	getUnitContact = (unit: Unit) => {
		return this.getUnitContactImpl(unit);
	};

	private getUnitContactImpl = (unit: Unit) => {
		const items = this.getItems();
		return items.find(c => c.contactType === "agentUser" && c.contactData.unitId === unit.unitId && c.contactData.product === unit.product);
	};

	getByUnit(body: QueryContacts, resolve?: (cs: DB_Contact<any>[]) => void) {
		XhrHttpModule
			.createRequest<Api_GetUnitContacts>(HttpMethod.POST, `request-api--contacts-get-by-unit`)
			.setRelativeUrl(`/v2/contacts/get-by-unit`)
			.setJsonBody(body)
			.setOnError(() => {
				ToastModule.toastError(`Error getting contacts for unit ${body.unitId}, product ${body.product}`);
			})
			.execute(async (response: DB_Contact<any>[]) => {
				if (response.find(r => r.contactType === "landline")) {
					this.isUnitIdWithLandline[body.unitId] = true;
				}
				await this.onQueryReturned(response)
				resolve?.(response)
			});
	}

	get(id: string): DB_Contact<any> | undefined {
		return super.get(id);
	}

	protected async onQueryReturned(items: DB_Contact<any>[]): Promise<void> {
		await super.onQueryReturned(items);
		this.queryConsumers.forEach(c => c());
	}

	protected async onEntryCreated(item: DB_Contact<any>): Promise<void> {
		this.query();
	}

	protected async onEntryUpdated(item: DB_Contact<any>): Promise<void> {
		this.query();
	}

	protected async onEntryDeleted(item: DB_Contact<any>, hardDelete?: boolean): Promise<void> {
		this.query();
	}

	softDelete(body: DeleteContact_Body) {
		XhrHttpModule
			.createRequest<Api_DeleteContact>(HttpMethod.POST, `request-api--contacts-delete`)
			.setRelativeUrl(`/v2/contacts/delete`)
			.setJsonBody(body)
			.setOnError(() => {
				ToastModule.toastError(`Error delete ${body._id} contact, check logs`);
			})
			.execute(() => {
				return this.getByUnit({...body.unit});
			});
	}

	hardDelete(body: DeleteContact_Body) {
		this
			.getDeleteSyncRequest(body)
			.execute(() => {
				return this.getByUnit({...body.unit});
			});
	}

	private getDeleteSyncRequest(body: DeleteContact_Body) {
		return XhrHttpModule
			.createRequest<Api_HardDeleteContact>(HttpMethod.POST, `request-api--contacts-hard-delete`)
			.setRelativeUrl(`/v2/contacts/hard-delete`)
			.setJsonBody(body)
			.setOnError(() => {
				ToastModule.toastError(`Error hard delete ${body._id} contact, check logs`);
			});
	}

	moveFamilyMemberFromUnit(idToDelete: string, oldOwner: Unit, owner: DB_Contact<"agentUser">, contact: PartialProperties<DB_Contact<"familyMember" | "group">, keyof DB_Object>, requestHandler: (b: boolean) => void) {
		this.getDeleteSyncRequest({_id: idToDelete, unit: oldOwner})
			.executeSync()
			.then(() => this.createSyncImpl(contact).executeSync())
			.then(() => requestHandler(true))
			.catch(() => requestHandler(false))
	}

	createSyncImpl(toCreate: PartialProperties<DB_Contact<any>, keyof DB_Object>) {
		return this
			.createRequest<ApiBinder_DBCreate<DB_Contact<any>>>(DefaultApiDefs.Create)
			.setJsonBody(toCreate)
			.setHandleRequestSuccess(req => {
				// @ts-ignore
				this.onEntryCreated(req.resolveResponse()).catch(console.error);
			})
	}

	setEmergencyContact(body: Body_SetEmergencyContact): BaseHttpRequest<Api_SetEmergencyContact> {
		return XhrHttpModule
			.createRequest<Api_SetEmergencyContact>(HttpMethod.POST, `request-api--contacts-create-many`)
			.setRelativeUrl(`/v2/contacts/set-emergency-contact`)
			.setJsonBody(body)
			.setHandleRequestSuccess(req => {
				return this.getByUnit({unitId: body.unitId, product: Elliq_ProductKey});
			})
			.execute();
	}

	protected onError = (request: BaseHttpRequest<ApiBinder_DBCreate<DB_Contact<any>>>, resError?: ErrorResponse<{
		phoneNumber: string,
		contactId: string
	}>): boolean => {
		//@ts-ignore
		const contact = JSON.parse(request.body).contact as PartialProperties<DB_Contact<"familyMember">, keyof DB_Object>;
		if (request.key === CREATE_CONTACT_KEY) {
			const error = resError?.error;
			if (error?.type === "refreshFeError") {
				ToastModule.toastError("Please refresh this page first and then do actions, contacts list was changed");
				return super.onError(request, resError);
			}

			if (error?.type === "duplicatePhoneNumber") {
				ToastModule.toastError(`Duplicate phone number - this phone number ${resError?.error?.body.phoneNumber} already exist in one of this unit contacts: ${resError?.error?.body.contactId}`);
				return super.onError(request, resError);
			}

			if (error?.type === "connectToMoreThanOneUnit") {
				ToastModule.toastError(`Can't edit phone number because this contact connect to more than 1 unit - please delete the contact and add a new one`);
				return super.onError(request, resError);
			}
		}

		return super.onError(request, resError);
	}
}

export const ContactsModule = new ContactsModule_Class({key: "contacts", relativeUrl: "/v2/contacts"}, "ContactsModule");
