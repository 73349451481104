import {RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Page_Playground} from "./pages/playground/Page_Playground";
import {ContactsList} from "./pages/contacts/ContactsList";
import {ImageTransfer} from "./pages/ui/file-components/ImageTransfer";
import {ContactsTable} from "./pages/contacts/ContactsTable";
import {CloudAssetsTable} from "./pages/general-cloud-assets/CloudAssetsTable";
import {RenderHome, RouteToIRApp, RouteToIRWebsite} from "./App";
import {VideoCallingWrapper} from "./pages/video-calling/VideoCallingWrapper";
import {AgentUserDetails} from "./pages/contacts/AgentUserDetails";
import {Playground_TaskSchedulerTable} from "./pages/playground/examples/Playground_TaskSchedulerTable";
import {ArchiveChats} from "./pages/playground/ArchiveChats";
import Query from "./pages/query/Query";
// import {VideoCallingAppSuggested} from "./pages/video-calling/VideoCallingAppSuggested";

export const Route_Video_Calling_InHouse = "videoCalling-inHouse";
export const Route_Video_Calling = "videoCalling";
export const Route_IR_Website = "IRWebsite";
export const Route_IR_App = "IRApp";
export const Route_Playground = "playground";
export const Route_InHouseRoute = "inHouse";
export const Route_Contacts = "contacts";
export const Route_Archive = "archive";
export const Route_Contacts_Table = "contactsTable";
export const Route_Agent_User_Details = "AgentUserDetails"
export const Route_Cloud_Assets_Table = "cloudAssetsTable";
export const Route_Image_Transfer = "imageTransfer";
export const PARAM_UNIT_ID = "unitId";
export const PARAM_PRODUCT = "product";
export const PARAM_USER_GROUP = "userGroup";
export const PARAM_VERSION = "version";
export const PARAM_UNIT_CLOUD_ASSET_KEY = "key";
export const Route_Task_Scheduler = "/taskScheduler"
export const Route_Query = "query"

export const registerRoutes = () => {
    RoutingModule.clearRoutes();
    RoutingModule.addRoute(Route_Task_Scheduler, "/task-scheduler", Playground_TaskSchedulerTable)
    RoutingModule.addRoute(Route_Contacts_Table, `/contacts-table`, ContactsTable);
    RoutingModule.addRoute(Route_Agent_User_Details, `/agent-user-details`, AgentUserDetails);
    RoutingModule.addRoute(Route_Cloud_Assets_Table, `/cloud-assets-table`, CloudAssetsTable).setLabel("Assets Table");
    RoutingModule.addRoute(Route_Contacts, `/contacts`, ContactsList).setLabel("Contacts");
    RoutingModule.addRoute(Route_Archive, `/archive`, ArchiveChats).setLabel("Archive Chats");
    RoutingModule.addRoute(Route_Image_Transfer, `/image-transfer`, ImageTransfer).setLabel("Image Transfer");
    // RoutingModule.addRoute(Route_Video_Calling_InHouse, "/pwa", VideoCallingAppSuggested).setLabel("Video Calling");

    RoutingModule.addRoute(Route_Video_Calling, "/", VideoCallingWrapper).setLabel("Video Calling").setExact(false);
    RoutingModule.addRoute(Route_IR_Website, "/info", RouteToIRWebsite).setLabel("IR Website");
    RoutingModule.addRoute(Route_IR_App, "/app", RouteToIRApp).setLabel("ElliQ Connect App").setExact(false);

    RoutingModule.addRoute(Route_Query, "/query", Query).setLabel("Query").setExact(false);


    //home route should be declared last
    RoutingModule.addRoute(Route_InHouseRoute, "/", RenderHome).setLabel("Home").setExact(false);
    RoutingModule.addRoute(Route_Playground, "/", Page_Playground).setLabel("Playground").setExact(false);
};
