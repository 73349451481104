import {SQL_CloudAsset, SQL_CloudAssetWithUrl} from "@app/ir-q-app-common/types/cloud-asset";
import {Module, PartialProperties} from "@intuitionrobotics/ts-common";
import {ServerApiModule} from "@modules/ServerApiModule";
import {AxiosResponse} from "axios";

export class CloudAssetsModule_Class
	extends Module {

	private dbAssetsWithImages: { [key: string]: SQL_CloudAssetWithUrl[] } = {};
	private files: {[key: string]: File} = {}

	public getDbAssetsWithImages() {
		return this.dbAssetsWithImages;
	}
	public getDbAssetsWithImagesByKey(key: string) {
		return this.dbAssetsWithImages[key] || [];
	}

	public fetchUrlsOfImages = async (asset_type: string) => {
		const client = await ServerApiModule.getClientWithAuthorization();
		await client.get<SQL_CloudAssetWithUrl[]>(`/asset/get-general/${asset_type}`)
			.catch(error => this.logError("Failed to get assets:", error))
			.then(response => {
				if (!this.dbAssetsWithImages[asset_type])
					this.dbAssetsWithImages[asset_type] = []

				this.dbAssetsWithImages[asset_type] = (response as AxiosResponse<SQL_CloudAssetWithUrl[]>).data.map(a => {
					a.asset_type = a.asset_type.toLowerCase().replace(/_/g, "-")
					a.media_type = a.media_type.toLowerCase()
					a.plan_name = a.plan_name?.toLowerCase()
					return a;
				});
			});
	};

	public setFileInCache(feId: string, file: File) {
		this.files[feId] = file;
	}

	public replaceFileInCache(feId:string, cacheId: string) {
		const file = this.files[feId];
		if (!file)
			return

		delete this.files[feId]
		this.files[cacheId] = file;
	}

	public getFile(cacheId: string) {
		return this.files[cacheId];
	}

	public async delete(item: SQL_CloudAssetWithUrl) {
		const client = await ServerApiModule.getClientWithAuthorization();

		await client
			.post(`/asset/delete-general`, {id: item.id})
			.catch(resError => this.logError("Failed to delete cloud asset:", resError))
			.then(_ => {
				this.dbAssetsWithImages[item.asset_type] = this.dbAssetsWithImages[item.asset_type].filter(asset => asset.id !== item.id);
			});
	}

	public async update(asset: SQL_CloudAssetWithUrl) {
		const client = await ServerApiModule.getClientWithAuthorization();

		asset.media_type = asset.media_type.toUpperCase();
		await client
			.post<SQL_CloudAssetWithUrl>(`/asset/update`, {asset, tags: asset.tags, landmarks: asset.landmarks, captions: asset.captions})
			.catch(resError => this.logError("Failed to update cloud asset:", resError))
			.then(response => {
				this.dbAssetsWithImages[asset.asset_type] = this.dbAssetsWithImages[asset.asset_type].filter(asset => asset.id !== asset.id)

				const cacheId = asset.cache_id;
				if (cacheId && this.files[cacheId])
					asset.signed_url = URL.createObjectURL(this.files[cacheId]);

				const updatedAssets = (response as AxiosResponse<SQL_CloudAssetWithUrl>).data;
				updatedAssets.asset_type = updatedAssets.asset_type.toLowerCase().replace(/_/g, "-")
				updatedAssets.media_type = updatedAssets.media_type.toLowerCase()
				updatedAssets.plan_name = updatedAssets.plan_name?.toLowerCase()
				this.dbAssetsWithImages[asset.asset_type].push(updatedAssets);
			});
	}

	async create(cloudAsset: PartialProperties<SQL_CloudAsset, "id">) {
		const client = await ServerApiModule.getClientWithAuthorization();

		cloudAsset.media_type = cloudAsset.media_type.toUpperCase();
		await client
			.post<SQL_CloudAssetWithUrl>(`/asset/create-general`, cloudAsset)
			.catch(resError => this.logError("Failed to create cloud asset:", resError))
			.then(response => {
				const asset = (response as AxiosResponse<SQL_CloudAssetWithUrl>).data;
				asset.asset_type = asset.asset_type.toLowerCase().replace(/_/g, "-")
				asset.media_type = asset.media_type.toLowerCase()
				asset.plan_name = asset.plan_name?.toLowerCase()

				const cacheId = asset.cache_id;
				if (cacheId && this.files[cacheId])
					asset.signed_url = URL.createObjectURL(this.files[cacheId]);

				const exitAssetIndex = this.dbAssetsWithImages[asset.asset_type].findIndex(_asset => _asset.id === asset.id);
				if (exitAssetIndex !== -1)
					this.dbAssetsWithImages[asset.asset_type].splice(exitAssetIndex, 1);

				this.dbAssetsWithImages[asset.asset_type].push(asset);
			});
	}
}


export const CloudAssetsModule = new CloudAssetsModule_Class("CloudAssetsModule");
